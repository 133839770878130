import axios from 'axios'

let profileEditTimeout = null
const PROFILE_EDIT_TIMEOUT_DELAY = 2000

export const profileStore = {
    state: {
        isProfileLoading: false,
        isProfileLoaded: false,
        profileLoadError: null,
        profile: {},
        profileSavingState: '',
        profileSaveError: null
    },
    getters: {
        isProfileLoading: state => () => state.isProfileLoading,
        isProfileLoaded: state => () => state.isProfileLoaded,
        profileLoadError: state => () => state.profileLoadError,
        profile: state => () => state.profile,
        profileSavingState: state => () => state.profileSavingState,
        profileSaveError: state => () => state.profileSaveError
    },
    mutations: {
        SET_IS_PROFILE_LOADING: (state, payload) => state.isProfileLoading = payload,
        SET_IS_PROFILE_LOADED: (state, payload) => state.isProfileLoaded = payload,
        SET_PROFILE_LOAD_ERROR: (state, payload) => state.profileLoadError = payload,
        SET_PROFILE: (state, payload) => state.profile = payload,
        SET_PROFILE_SAVING_STATE: (state, payload) => state.profileSavingState = payload,
        SET_PROFILE_SAVE_ERROR: (state, payload) => state.profileSaveError = payload
    },
    actions: {
        loadProfile: async ({ commit, getters }) => {
            commit('SET_IS_PROFILE_LOADING', true)
            commit('SET_PROFILE_LOAD_ERROR', null)
            return axios.get(getters.backendPath() + '/profile')
                .then(response => {
                    const data = response.data
                    if (data.type && data.type === 'ERROR') {
                        commit('SET_PROFILE', {})
                        commit('SET_PROFILE_LOAD_ERROR', data.error)
                    }
                    else {
                        commit('SET_PROFILE', data)
                        commit('SET_IS_PROFILE_LOADED', true)
                    }
                    commit('SET_IS_PROFILE_LOADING', false)
                    return data
                })
                .catch(error => {
                    commit('SET_IS_PROFILE_LOADING', false)
                    commit('SET_PROFILE_LOAD_ERROR', error.message || error.response.data.message)
                })
        },
        saveProfile: async ({ getters, commit }, payload) => {
            commit('SET_PROFILE_SAVING_STATE', 'SAVING')
            return axios.post(
                getters.backendPath() + '/profile',
                payload
            )
                .then(() => {
                    commit('SET_PROFILE_SAVING_STATE', 'AUTOSAVED')
                    commit('SET_PROFILE_SAVE_ERROR', null)
                    commit('SET_IS_SOMETHING_SAVED', true)
                }, response => {
                    let message = response.data.message
                    if (response.data && response.data.status === 401) {
                        message = 'no valid login'
                    }
                    commit('SET_PROFILE_SAVE_ERROR', message)
                    commit('SET_ERROR_INTO_ERROR_LIST', message)
                })
        },
        editProfile: async ({ commit, dispatch }, payload) => {
            commit('SET_PROFILE_SAVING_STATE', '')
            commit('SET_PROFILE', payload.profile)
            clearTimeout(profileEditTimeout)
            profileEditTimeout = setTimeout(() => {
                dispatch('saveProfile', payload)
            }, PROFILE_EDIT_TIMEOUT_DELAY)
        },
        unloadProfile: ({ commit }) => {
            commit('SET_PROFILE', {})
            commit('SET_IS_PROFILE_LOADED', false)
        },
        savePassword: ({ commit, getters }, payload) => {
            commit('SET_PROFILE_SAVING_STATE', 'SAVING')
            return axios.post(
                getters.backendPath() + '/password',
                payload
            )
                .then(() => {
                    commit('SET_PASSWORD', payload.password)
                    commit('SET_PROFILE_SAVING_STATE', 'AUTOSAVED')
                    commit('SET_PROFILE_SAVE_ERROR', null)
                    commit('SET_IS_SOMETHING_SAVED', true)
                }, response => {
                    let message = response.data.message
                    if (response.data && response.data.status === 401) {
                        message = 'no valid login'
                    }
                    commit('SET_PROFILE_SAVE_ERROR', message)
                    commit('SET_ERROR_INTO_ERROR_LIST', message)
                })
        }
    }
}
