import axios from 'axios'
import { findItem } from '../utils/content.utils.js'

export const internStore = {
    state: {
        isInternBaseDataLoading: false,
        isInternBaseDataLoaded: false,
        internBaseDataLoadError: null,
        internBaseData: {}
    },
    getters: {
        isInternBaseDataLoading: state => () => state.isInternBaseDataLoading,
        isInternBaseDataLoaded: state => () => state.isInternBaseDataLoaded,
        internBaseDataLoadError: state => () => state.internBaseDataLoadError,
        internBaseData: state => () => state.internBaseData,
        getInternBaseDataById: state => (itemId) => state.internBaseData && state.internBaseData.children && findItem(itemId, state.internBaseData.children)
    },
    mutations: {
        SET_IS_INTERN_BASE_DATA_LOADING: (state, payload) => state.isInternBaseDataLoading = payload,
        SET_IS_INTERN_BASE_DATA_LOADED: (state, payload) => state.isInternBaseDataLoaded = payload,
        SET_INTERN_BASE_DATA_LOAD_ERROR: (state, payload) => state.internBaseDataLoadError = payload,
        SET_INTERN_BASE_DATA: (state, payload) => state.internBaseData = payload
    },
    actions: {
        loadInternBaseData: ({ commit, getters }) => {
            commit('SET_IS_INTERN_BASE_DATA_LOADING', true)
            commit('SET_INTERN_BASE_DATA_LOAD_ERROR', null)
            return axios.get(getters.backendPath() + '/intern')
                .then(response => {
                    const data = response.data
                    commit('SET_INTERN_BASE_DATA', data)
                    commit('SET_IS_INTERN_BASE_DATA_LOADING', false)
                    commit('SET_IS_INTERN_BASE_DATA_LOADED', true)
                    return data
                })
                .catch(error => {
                    commit('SET_IS_INTERN_BASE_DATA_LOADING', false)
                    commit('SET_INTERN_BASE_DATA_LOAD_ERROR', error)
                })
        },
        unloadIntern: ({ dispatch }) => {
            dispatch('unloadUser')
            dispatch('unloadAccess')
            dispatch('unloadNavigation')
            dispatch('unloadSubMenuLinks')
            dispatch('unloadEvents')
            dispatch('unloadUsers')
            dispatch('unloadProfile')
            dispatch('unloadEvent')
            dispatch('unloadUserRoles')
            dispatch('unloadSurvey')
            dispatch('unloadParticipants')
            dispatch('unloadExpenses')
        }
    }
}
