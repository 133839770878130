<template>
    <div id="users">
        <div v-if="usersLoadError">
            {{ usersLoadError }}
        </div>
        <div v-else-if="users && users.length">
            <div class="tab-bar">
                <div class="tab-bar-start">
                    <div v-if="!isCreateUserActive" class="tab" @click="() => setIsCreateUserActive(true)">
                        create new user
                    </div>
                    <div v-if="isCreateUserActive && !isNewUserSaving" class="tab" @click="() => setIsCreateUserActive(false)">
                        cancel new user
                    </div>
                    <div v-if="isCreateUserActive && !isNewUserSaving" class="tab" :class="{ disabled: !isUsernameAndPasswordGiven }" @click="() => saveNewUser()">
                        save new user
                    </div>
                </div>
                <div class="tab-bar-end">
                    <div class="saving-state">
                        {{ newUserSaveError || newUserSavingState }}
                    </div>
                </div>
            </div>
            <div class="user-create-form" v-if="isCreateUserActive">
                <input class="name-input"
                    :value="newUser.name"
                    @input="event => onEditNewUser('name', event.target.value)"
                    placeholder="username"
                >
                <input class="password-input"
                    :value="newUser.password"
                    @input="event => onEditNewUser('password', event.target.value)"
                    placeholder="password"
                >
            </div>
            <div class="table">
                <div class="table-head">
                    <div class="identifier-column">
                    </div>
                    <div class="events-column">
                        events
                    </div>
                    <div class="deletion-column">
                        deletion
                    </div>
                </div>
                <div class="table-body">
                    <div v-for="user in sortedUsers"
                        :key="user.userId"
                        class="row"
                    >
                        <div class="identifier-column"
                            :class="{ 'can-open-user': canOpenUser }"
                            @click="() => openUser(user)"
                        >
                            {{ user.name }}
                        </div>
                        <div class="events-column" @click="() => editUserEvents(user)">
                            {{ user.eventCount }}
                        </div>
                        <div class="deletion-column"
                            :class="{ 'deletion-is-due': user.isDueForDeletion }"
                        >
                            {{ user.isDueForDeletion ? formatDate(user.isDueForDeletion) : 'none' }}
                        </div>
                    </div>
                </div>
            </div>
            <Modal>
                <EventAssigner :user="userForEvents"></EventAssigner>
            </Modal>
        </div>
    </div>
</template>

<script>
import Modal from '@/components/modal.vue'
import EventAssigner from '@/modules/events/event-assigner.vue'

export default {
    name: 'Users',
    props: {
    },
    components: {
        Modal,
        EventAssigner
    },
    data: function() {
        return {
            isCreateUserActive: false,
            newUser: {
                name: null,
                password: null
            },
            sortBy: {
                name: true
            },
            userForEvents: null
        }
    },
    computed: {
        users() {
            return this.$store.getters.users()
        },
        sortedUsers() {
            const users = this.$store.getters.users()
            if (this.sortBy.name) {
                return users.sort((a, b) => {
                    const nameA = a.name.toUpperCase()
                    const nameB = b.name.toUpperCase()
                    if (nameA < nameB) {
                        return -1
                    }
                    if (nameA > nameB) {
                        return 1
                    }
                    return 0
                })
            }
            else {
                return users
            }
        },
        usersLoadError() {
            return this.$store.getters.usersLoadError()
        },
        isNewUserSaving() {
            return this.$store.getters.isNewUserSaving()
        },
        newUserSavingState() {
            return this.$store.getters.newUserSavingState()
        },
        newUserSaveError() {
            return this.$store.getters.newUserSaveError()
        },
        isUsernameAndPasswordGiven() {
            return this.newUser.name && this.newUser.name.length > 0 && this.newUser.password && this.newUser.password.length > 0
        },
        canOpenUser() {
            return this.$store.getters.hasAccess('openUser')
        }
    },
    created() {
        this.$store.dispatch('loadUsers')
        this.$store.dispatch('loadEvents')
    },
    methods: {
        setIsCreateUserActive(isActive) {
            this.isCreateUserActive = isActive
        },
        onEditNewUser(field, value) {
            this.newUser[field] = value
        },
        saveNewUser() {
            if (this.isUsernameAndPasswordGiven) {
                this.$store.dispatch('saveNewUser', { newUser: this.newUser })
                    .then(({ isNewUserSaved } = {}) => {
                        if (isNewUserSaved) {
                            this.isCreateUserActive = false
                            this.newUser.name = null
                            this.newUser.password = null
                        }
                    })
            }
        },
        editUserEvents(user) {
            this.userForEvents = user
            this.$store.dispatch('openModal', { modalTitle: 'assign user' })
        },
        onUserEventsEdited() {
            this.userForEvents = null
        },
        openUser(user) {
            this.$store.dispatch('logto', {
                username: user.name,
                routeAfter: {
                    name: 'profile'
                },
                returnRoute: this.$store.getters.route()
            })
        },
        formatDate(deletionDate) {
            const date = new Date(deletionDate)
            return date.toLocaleDateString() 
        }
    }
}
</script>

<style lang="scss" scoped>
    #users {
        width: 100%;

        .user-create-form {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: $view-padding;

            .name-input {
                width: 300px;
                border-right: $thin-line solid $white;
            }

            .password-input {
                width: 300px;
            }
        }

        .identifier-column {
            width: 250px;
        }

        .can-open-user {
            cursor: pointer;

            &:hover {
                color: $secondary;
            }
        }

        .events-column {
            width: 90px;

            &:hover {
                color: $secondary;
                cursor: pointer;
            }
        }

        .deletion-column {
            &.deletion-is-due {
                color: $warning;
            }
        }
    }
</style>
