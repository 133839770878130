<template>
    <div id="content-box" class="content-box" :class="{ scroll: scroll, 'pause-scroll': !isPageTitleAnimationDone || isMenuOpen }">
        <div class="navbar-spacer"
            :style="{ 'height': navBarSpace + 'px' }">
        </div>
        <div class="horizontal-box">
            <div class="title-spacer"
                :style="{ 'width': (isMediumScreen || isBigScreen) ? titleBoxWidth + 'px' : navbarMargin + 'px' }"
            />
            <div class="content">
                <slot/>
            </div>
            <div class="right-spacer"
                :style="{ 'width': (isMediumScreen || isBigScreen) ? navBarSpace + 'px' : navbarMargin + 'px' }"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ContentBox',
        components: {
        },
        props: {
            scroll: Boolean
        },
        created() {
        },
        data: function () {
            return {
            }
        },
        computed: {
            isMediumScreen() {
                return this.$store.getters.isMediumScreen() || false
            },
            isBigScreen() {
                return this.$store.getters.isBigScreen() || false
            },
            navbarMargin() {
                return this.$store.getters.navbarMargin() || 40
            },
            navBarSpace() {
                return (2 * this.$store.getters.navbarMargin() + this.$store.getters.navbarHeight()) || 0
            },
            titleBoxWidth() {
                return this.$store.getters.titleBoxWidth() || 0
            },
            isPageTitleAnimationDone() {
                return this.$store.getters.isPageTitleAnimationDone()
            },
            isMenuOpen() {
                return this.$store.getters.isMenuOpen()
            }
        },
        methods: {
        }
    }
</script>

<style scoped lang="scss">
    .content-box {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        &.scroll {
            height: auto;
            overflow: auto;
        }
    }

    .navbar-spacer {
        width: 100%;
        flex-shrink: 0;
    }

    .horizontal-box {
        position: relative;
        flex-grow: 1;
        display: flex;
        flex-direction: row;
    }

    .title-spacer {
        flex-shrink: 0;
    }

    .content {
        flex-grow: 1;
        max-width: 800px;
    }

    .right-spacer {
        flex-shrink: 0;
    }

    @media (max-width: 900px) {
        .title-spacer {
            min-width: $view-padding;
        }

        .right-spacer {
            min-width: $view-padding;
        }

        .content-box {
            &.pause-scroll {
                height: 100%;
                overflow: hidden;
            }
        }
    }
</style>
