<template>
    <div id="survey">
        <div v-if="isSurveyLoading && !survey">
            Der Fragebogen wird geladen.
        </div>
        <div v-else>
            <div v-if="surveyProgressState === 'preparation'" class="progress-state-message">
                Dieser Fragebogen wird noch vorbereitet
            </div>
            <div v-else-if="editTimeIsOver" class="progress-state-message underlined">
                Die Bearbeitungszeit ist vorbei
            </div>
            <div v-if="surveyProgressState !== 'preparation'">
                <Question
                    v-for="({ questionId, question, type, options }, index) in survey.questions"
                    :key="index"
                    :question="question"
                    :type="type"
                    :options="options"
                    :answer="surveyUserData[questionId]"
                    :locked="!canEditSurveyUserData || editTimeIsOver"
                    @filling="value => onChangeUserData(questionId, value)"
                />
            </div>
            <br>
            <div v-if="surveyUserDataSaveError">
                {{ surveyUserDataSaveError }}
            </div>
            <div v-else class="saving-state">
                {{ surveyUserDataSavingState }}
            </div>
            <div class="bottom-spacer">
            </div>
        </div>
    </div>
</template>

<script>
import Question from '@/modules/survey/question.vue'

export default {
    name: 'Survey',
    props: {
        eventId: Number,
        surveyId: Number
    },
    components: {
        Question
    },
    data() {
        return {
            surveyVisibleStates: [
                'open',
                'closed'
            ]
        }
    },
    computed: {
        route() {
            return this.$store.getters.route()
        },
        recentEventId() {
            return this.eventId || this.route.params.eventId
        },
        event() {
            return this.$store.getters.event()
        },
        recentSurveyId() {
            const surveyId = this.surveyId || this.route.params.surveyId
            return surveyId && parseInt(surveyId)
        },
        surveyProgressState() {
            const eventSurveys = (this.event && this.event.surveys && this.event.surveys) || []
            const eventSurvey = eventSurveys.find(survey => parseInt(survey.surveyId) === parseInt(this.recentSurveyId))
            return eventSurvey ? eventSurvey.state : false
        },
        editTimeIsOver() {
            return this.surveyProgressState === 'closed'
        },
        isSurveyLoading() {
            return this.$store.getters.isSurveyLoading()
        },
        survey() {
            return this.$store.getters.survey()
        },
        canEditSurveyUserData() {
            return this.$store.getters.hasAccess('editSurveyUserData')
        },
        surveyUserData() {
            return this.$store.getters.surveyUserData()
        },
        surveyUserDataSaveError() {
            return this.$store.getters.surveyUserDataSaveError()
        },
        surveyUserDataSavingState() {
            return this.$store.getters.surveyUserDataSavingState()
        }
    },
    created() {
        this.load()
    },
    watch: {
        recentSurveyId() {
            this.load()
        },
        surveyProgressState() {
            this.load()
        },
        event(event) {
            if (event && this.$store.getters.isEventAccessLoaded() && !this.$store.getters.hasAccess('surveys')) {
                this.$router.push({ name: 'event', params: { eventId: event.eventId } })
            }
        }
    },
    methods: {
        load() {
            const isSurveyVisible = this.surveyProgressState && this.surveyVisibleStates.includes(this.surveyProgressState)
            const baseDataSurveyRoute = this.event.routes && this.recentSurveyId && this.event.routes.find(routeItem => routeItem.params.surveyId === this.recentSurveyId)
            if (baseDataSurveyRoute && baseDataSurveyRoute.title) {
                this.$store.dispatch('setViewTitle', baseDataSurveyRoute.title)
            }
            if (this.recentEventId && this.recentSurveyId && isSurveyVisible) {
                this.$store.dispatch('loadSurvey', {
                    eventId: this.recentEventId,
                    surveyId: this.recentSurveyId
                })
                this.$store.dispatch('loadSurveyUserData', {
                    eventId: this.recentEventId,
                    surveyId: this.recentSurveyId
                })
            }
            else {
                this.$store.dispatch('unloadSurvey')
            }
        },
        onChangeUserData(questionId, value) {
            if (!this.editTimeIsOver) {
                const userData = this.surveyUserData
                userData[questionId] = value
                this.$store.dispatch('editSurveyUserData', {
                    eventId: this.recentEventId,
                    surveyId: this.recentSurveyId,
                    surveyUserData: userData
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    #survey {
        width: 100%;

        .progress-state-message {
            width: 100%;
            margin-bottom: $view-padding;

            &.underlined {
                border-bottom: 2px solid $primary;
            }
        }

        .saving-state {
            color: $secondary;
            height: $view-padding;
        }

        .bottom-spacer {
            height: $botttom-spacer-height;
        }
    }
</style>
