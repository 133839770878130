<template>
    <div class="arrow-box"
        :class="{ animated: animated}"
        :style="{ width: size + 'px', height: size + 'px', transform: 'rotate(' + angle + 'deg)' }"
    >
        <div class="left-wing">
        </div>
        <div class="center-line">
        </div>
        <div class="right-wing">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Arrow',
        components: {
        },
        props: {
            angle: Number,
            size: Number,
            animated: Boolean
        },
        created() {
        },
        data: function () {
            return {
            }
        },
        computed: {
        },
        methods: {
        }
    }
</script>

<style scoped lang="scss">
    @keyframes grow {
        from { height: 0%; }
        to { height: 95%; }
    }

    @keyframes unfoldleft {
        0% {
            height: 0%;
        }
        49% {
            height: 0%;
            left: 44.4%;
        }
        50% {
            height: 50%;
            transform: rotate(0deg);
        }
        100% {
            height: 50%;
            left: 31%;
            transform: rotate(45deg);
        }
    }

    @keyframes unfoldright {
        0% {
            height: 0%;
        }
        49% {
            height: 0%;
            left: 44.4%;
        }
        50% {
            height: 50%;
            transform: rotate(180deg);
        }
        100% {
            height: 50%;
            left: 59%;
            transform: rotate(135deg);
        }
    }

    .arrow-box {
        position: relative;

        .left-wing,
        .center-line,
        .right-wing {
            position: absolute;
            width: 11.1%;
            background-color: $primary;
        }

        .left-wing,
        .right-wing {
            top: -3%;
            height: 50%;
        }

        .left-wing {
            left: 31%;
            transform: rotate(45deg);
        }

        .center-line {
            height: 95%;
            bottom: 0%;
            left: 44.4%;
        }

        .right-wing {
            left: 59%;
            transform: rotate(135deg);
        }

        &.animated {
            .left-wing,
            .center-line,
            .right-wing {
                height: 0;
            }

            .center-line {
                animation: grow $nav-animation-length forwards;
            }

            .left-wing {
                animation: unfoldleft calc(2 * $nav-animation-length) forwards;
            }

            .right-wing {
                animation: unfoldright calc(2 * $nav-animation-length) forwards;
            }
        }
    }

    @media (hover: hover) and (pointer: fine) {
        .arrow-box:hover {
            cursor: pointer;

            .left-wing,
            .center-line,
            .right-wing {
                background-color: $secondary;
            }
        }
    }
</style>
