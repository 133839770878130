<template>
    <div id="events">
        <div v-if="events">
            <div class="events-table-container">
                <div class="table">
                    <div class="table-head">
                    </div>
                    <div class="table-body">
                        <div v-for="event in events" :key="event.id"
                            class="row event-item"
                            @click="() => openEvent(event.eventId)"
                        >
                            <div class="event-name">{{ event.name }}</div>
                            <div class="event-date">{{ formatDate(event) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Events',
    props: {
    },
    components: {
    },
    computed: {
        events() {
            return this.$store.getters.events()
        }
    },
    created() {
        this.$store.dispatch('loadEvents')
    },
    methods: {
        openEvent(eventId) {
            this.$router.push({ path: 'event/' + eventId })
        },
        formatDate(event) {
            if (event && event.date) {
                const date = new Date(event.date)
                return date.toLocaleDateString() 
            }
            else {
                'Date unknown.'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    #events {
        width: 100%;
    }

    .event-item {
        display: flex;

        &:hover {
            cursor: pointer;
            color: $secondary;
        }

        .event-name {
            width: 60%;
        }

        .event-date {
            width: 40%;
            text-align: right;
        }
    }
</style>
