<template>
    <div id="participants">
        <div v-if="isParticipantsLoading || isParticipantsRolesLoading">
            Lade Teilnehmerliste...
        </div>
        <div v-else-if="participantsLoadError || accessOptionsLoadError || participantsRolesLoadError || eventRoleAccessDefaultsLoadError">
            {{ participantsLoadError ? participantsLoadError : "" }}
            {{ accessOptionsLoadError ? accessOptionsLoadError : "" }}
            {{ participantsRolesLoadError ? participantsRolesLoadError : "" }}
            {{ eventRoleAccessDefaultsLoadError ? eventRoleAccessDefaultsLoadError : "" }}
        </div>
        <div v-else>
            <div class="tab-bar">
                <div class="tab-bar-start">
                    <div
                        v-if="canViewParticipantsStatus"
                        class="tab table-selection-option"
                        :class="{ active: isStatusTableActive }"
                        @click="() => setTableActive('status')"
                    >
                        Status
                    </div>
                    <div
                        v-if="canViewParticipantsRoles"
                        class="tab table-selection-option"
                        :class="{ active: isRolesTableActive }"
                        @click="() => setTableActive('roles')"
                    >
                        Rollen und Rechte
                    </div>
                </div>
                <div class="tab-bar-end">
                    <div class="saving-state">
                        {{ participantsSaveError || participantsSavingState }}
                    </div>
                </div>
            </div>
            <div class="table" :class="{ hidden: !isStatusTableActive }">
                <div class="table-head">
                    <div class="identifier-column"></div>
                        <div
                            class="status-column"
                            v-for="survey in surveys"
                            :key="survey.surveyId"
                        >
                            {{ survey.title }}
                        </div>
                    </div>
                <div class="table-body"
                    :class="{ 'has-active-row': toggledParticipant }"
                >
                    <div v-for="participant in participants" :key="participant.userId">
                        <div class="row">
                            <div class="identifier-column">
                                {{ participant.name }}
                            </div>
                            <div
                                class="status-column"
                                v-for="survey in surveys"
                                :key="survey.surveyId"
                                :class="{ 'can-open-user': canOpenUser || canOpenSurveys }"
                                @click="() => openParticiantSurvey(participant, survey.surveyId)"
                            >
                                {{ getSurveyStatus(participant, survey.surveyId) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table" :class="{ hidden: !isRolesTableActive }">
                <div class="table-head">
                </div>
                <div
                    class="table-body"
                    :class="{ 'has-active-row': toggledParticipant }"
                >
                    <div
                        class="participant-row-box"
                        :class="{ active: toggledParticipant === participant.userId }"
                        v-for="participant in participants"
                        :key="participant.userId"
                    >
                        <div
                            class="row"
                            :class="{ active: toggledParticipant === participant.userId }"
                        >
                            <div class="identifier-column"
                                :class="{ 'can-view-access': canViewParticipantsAccess }"
                                @click="() => toggleParticipant(participant)"
                            >
                                {{ participant.name }}
                            </div>
                            <div
                                v-for="role in participantsRoles"
                                :key="role"
                                class="role-column"
                                :class="{
                                    active: participant.roles && participant.roles.includes(role),
                                }"
                                @click="() => setParticipantRole(participant, role)"
                            >
                                {{ role }}
                            </div>
                        </div>
                        <div class="row"
                            v-if="toggledParticipant === participant.userId"
                            :class="{ active: toggledParticipant === participant.userId }"
                        >
                            <ParticipantAccess :participant="participant" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ParticipantAccess from "@/modules/events/participant-access.vue";

export default {
    name: "Participants",
    components: {
        ParticipantAccess,
    },
    props: {
        eventId: Number,
    },
    data: function () {
        return {
        isStatusTableActive: true,
        isRolesTableActive: false,
        toggledParticipant: null,
        };
    },
    computed: {
        route() {
        return this.$store.getters.route();
        },
        isParticipantsLoading() {
        return this.$store.getters.isParticipantsLoading();
        },
        participantsLoadError() {
        return this.$store.getters.participantsLoadError();
        },
        participants() {
        return this.$store.getters.participants();
        },
        accessOptionsLoadError() {
        return this.$store.getters.accessOptionsLoadError();
        },
        isParticipantsRolesLoading() {
        return this.$store.getters.isParticipantsRolesLoading();
        },
        participantsRolesLoadError() {
        return this.$store.getters.participantsRolesLoadError();
        },
        participantsRoles() {
        return this.$store.getters.participantsRoles();
        },
        participantsSavingState() {
        return this.$store.getters.participantsSavingState();
        },
        participantsSaveError() {
        return this.$store.getters.participantsSaveError();
        },
        event() {
        return this.$store.getters.event();
        },
        surveys() {
        return (this.event && this.event.surveys) || [];
        },
        canViewParticipantsStatus() {
        return this.$store.getters.hasAccess("participantsStatus");
        },
        canViewParticipantsRoles() {
        return this.$store.getters.hasAccess("participantsRoles");
        },
        canOpenUser() {
        return this.$store.getters.hasAccess("openUser");
        },
        canOpenSurveys() {
        return this.$store.getters.hasAccess("openParticipantSurvey");
        },
        canEditAccess() {
        return this.$store.getters.hasAccess("editParticipantAccess");
        },
        canViewParticipantsAccess() {
        return this.$store.getters.hasAccess("participantsAccess");
        },
        eventRoleAccessDefaultsLoadError() {
            return this.$store.getters.eventRoleAccessDefaultsLoadError();
        },
        eventRoleAccessDefaults() {
            return this.$store.getters.eventRoleAccessDefaults();
        }
    },
    created() {
        this.load();
    },
    beforeUnmount() {},
    watch: {
        event(event) {
            if (event && this.$store.getters.isEventAccessLoaded() && !this.$store.getters.hasAccess('participants')) {
                this.$router.push({ name: 'event', params: { eventId: event.eventId } })
            }
        }
    },
    methods: {
        load() {
            const eventId = this.eventId || this.route.params.eventId
            this.$store.dispatch("loadParticipants", { eventId })
            this.$store.dispatch("loadAccessOptions", { eventId })
            this.$store.dispatch("loadParticipantsRoles", { eventId })
            this.$store.dispatch("loadEventRoleAccessDefaults", { eventId })
        },
        setTableActive(tableName) {
            if (tableName === "status") {
                this.isStatusTableActive = true;
                this.isRolesTableActive = false;
            } else if (tableName === "roles") {
                this.isStatusTableActive = false;
                this.isRolesTableActive = true;
            }
        },
        toggleParticipant(participant) {
        if (this.canViewParticipantsAccess) {
            if (participant.userId === this.toggledParticipant) {
            this.toggledParticipant = null;
            } else {
            this.toggledParticipant = participant.userId;
            }
        }
        },
        setParticipantRole(participant, role) {
            const participants = this.participants;
            const participantIndex = participants.findIndex((item) => item.userId === participant.userId)
            const roleIndex = participants[participantIndex].roles.findIndex((item) => item === role)
            let isAddingRole = false
            if (roleIndex > -1) {
                participants[participantIndex].roles.splice(roleIndex, 1);
            } else {
                participants[participantIndex].roles.push(role);
                isAddingRole = true
            }
            if (this.eventRoleAccessDefaults && this.eventRoleAccessDefaults[role]) {
                const participant = participants[participantIndex]
                const newAccess = this.eventRoleAccessDefaults[role]
                if (!participant.access) {
                    participants[participantIndex] = { ...participants[participantIndex], access: [] }
                }
                if (isAddingRole) {
                    newAccess.forEach(access => {
                        if (!participants[participantIndex].access.includes(access)) {
                            participants[participantIndex].access.push(access)
                        }
                    })
                }
                else {
                    const accessListToUnset = this.eventRoleAccessDefaults[role]
                    accessListToUnset.forEach(accessToUnset => {
                        let shouldUnset = true
                        participants[participantIndex].roles.forEach(roleName => {
                            if (this.eventRoleAccessDefaults[roleName].includes(accessToUnset)) {
                                shouldUnset = false
                            }
                        })
                        if (shouldUnset) {
                            const accessIndex = participants[participantIndex].access.findIndex((item) => item === accessToUnset)
                            if (accessIndex > -1) {
                                participants[participantIndex].access.splice(accessIndex, 1);
                            }
                        }
                    })
                }
            }
            this.$store.dispatch("editParticipants", {
                eventId: this.eventId || this.route.params.eventId,
                participants,
            });
        },
        getSurveyStatus(participant, surveyId) {
        const surveyStatus =
            participant.surveyStatus &&
            participant.surveyStatus.find((item) => item.surveyId === surveyId);
        if (surveyStatus) {
            if (surveyStatus.status === "not required") {
            return "-";
            } else {
            return parseInt((surveyStatus.status * 100).toFixed(2)) + "%";
            }
        } else {
            ("null");
        }
        },
        openParticiantSurvey(participant, surveyId) {
        if (this.canOpenUser) {
            this.$store.dispatch("logto", {
            username: participant.name,
            routeAfter: {
                name: "survey",
                params: {
                eventId: this.eventId || this.route.params.eventId,
                surveyId,
                },
            },
            returnRoute: this.$store.getters.route(),
            });
        } else if (this.canOpenSurveys) {
            const surveyStatus = participant.surveyStatus && participant.surveyStatus.find((item) => item.surveyId === surveyId);
            if (surveyStatus.status != "not required") {
                this.$store.dispatch('setSubViewTitle', participant.name)
                this.$store.dispatch("openParticipantSurvey", {
                    userId: participant.userId,
                    routeAfter: {
                        name: "survey",
                        params: {
                            eventId: this.eventId || this.route.params.eventId,
                            surveyId,
                        },
                    },
                    returnRoute: this.$store.getters.route(),
                })
            }
        }
        },
    },
};
</script>

<style lang="scss" scoped>
#participants {
  width: 100%;

    .identifier-column {
        width: 250px;
    }

    .status-column {
        width: 130px;
        text-align: center;
    }

    .role-column {
        width: 86px;
        text-align: center;

        &.active {
            color: $white;
            background-color: $primary;
        }

        &:hover {
            color: $secondary;
            cursor: pointer;
        }
    }


    .table {
        .table-body.has-active-row {
            .participant-row-box.active {
                padding-top: $thin-line;
                border-top: $thin-line solid $primary;
                border-bottom: $thin-line solid $primary;
                padding-bottom: calc(2 * $thin-line);
            }
    
            .role-column.active {
                background-color: $primary-super-light;
            }
    
            .row.active {
                &:first-of-type {
                    border-top: $thin-line solid $primary;
                }
    
                .role-column.active {
                    background-color: $primary;
                }
            }
        }
    }

    .can-view-access,
    .can-open-user {
        cursor: pointer;

        &:hover {
            color: $secondary;
        }
    }
}
</style>
