<template>
  <div class="login">
    <PageTitle class="page-title" color="white"/>
    <Login/>
  </div>
</template>

<script>
import PageTitle from '@/modules/navigation/page-title.vue'
import Login from '@/modules/login/login.vue';

export default {
  name: 'LoginView',
  components: {
    PageTitle,
    Login
  }
}
</script>
