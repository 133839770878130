<template>
    <div class="indicator-box"
        :class="{ hidden, appearing, disappearing, 'red-cross': showCross }"
        :style="{
            width: size + 'px',
            height: size + 'px',
            'margin-top': margin + 'px',
            'margin-right': margin + 'px',
        }"
        @click="showErrors"
    >
        <div class="turn-box">
            <div class="left-line">
            </div>
            <div class="right-line">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RequestOutcomeIndicator',
        components: {
        },
        props: {
            size: Number,
            margin: Number
        },
        data: function () {
            return {
                timeout: null,
                navAnimationLength: 250,
                visibleTime: 1500,
                hidden: true,
                appearing: false,
                disappearing: false,
                showCross: false
            }
        },
        computed: {
            isSomethingSaved() {
                return this.$store.getters.isSomethingSaved()
            },
            errorList() {
                return this.$store.getters.errorList()
            },
            errorListLength() {
                return this.errorList.length
            }
        },
        watch: {
            isSomethingSaved(isSaved) {
                if (isSaved && !this.timeout) {
                    this.appear()
                    this.timeout = setTimeout(this.disappear, this.navAnimationLength +  this.visibleTime)
                }
            },
            errorListLength(errorCount) {
                if (errorCount) {
                    this.showCross = true
                    this.appear()
                }
            }
        },
        methods: {
            appear() {
                this.disappearing = false
                this.appearing = true
                this.hidden = false
            },
            disappear() {
                this.appearing = false
                this.disappearing = true
                this.timeout = setTimeout(this.cleanUp, this.navAnimationLength)
            },
            cleanUp() {
                clearTimeout(this.timeout)
                this.timeout = null
                this.showCross = false
                this.hidden = true
                this.$store.commit('SET_IS_SOMETHING_SAVED', false)
                this.$store.commit('EMPTY_ERROR_LIST')
            },
            showErrors() {
                if (this.showCross) {
                    alert(this.errorList.join('\n'))
                    this.disappear()
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @keyframes drawleft {
        0% {
            width: 0%;
        }
        50% {
            width: 41%;
        }
        100% {
            width: 41%;
        }
    }

    @keyframes eraseleft {
        0% {
            width: 41%;
            left: 20%;
        }
        50% {
            width: 0%;
            left: 61%;
        }
        100% {
            width: 0%;
            left: 61%;
        }
    }

    @keyframes drawright {
        0% {
            height: 0%;
        }
        50% {
            height: 0%;
        }
        100% {
            height: 90%;
        }
    }

    @keyframes eraseright {
        0% {
            height: 90%;
            bottom: 10%
        }
        50% {
            height: 90%;
            bottom: 10%
        }
        100% {
            height: 0%;
            bottom: 100%
        }
    }

    @keyframes drawcrossleft {
        0% {
            width: 0%;
        }
        50% {
            width: 100%;
        }
        100% {
            width: 100%;
        }
    }

    @keyframes drawcrossright {
        0% {
            height: 0%;
        }
        50% {
            height: 0%;
        }
        100% {
            height: 100%;
        }
    }

    @keyframes erasecrossleft {
        0% {
            width: 100%;
            left: 0%;
        }
        50% {
            width: 0%;
            left: 100%;
        }
        100% {
            width: 0%;
            left: 100%;
        }
    }

    @keyframes erasecrossright {
        0% {
            height: 100%;
            bottom: 0%;
        }
        50% {
            height: 100%;
            bottom: 0%;
        }
        100% {
            height: 0%;
            bottom: 100%;
        }
    }

    .indicator-box {
        position: relative;
        pointer-events: all;

        .turn-box {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 16%;
            transform: rotate(30deg);
            margin: 0;
            padding: 0;
        }

        .left-line,
        .right-line {
            position: absolute;
            background-color: $secondary;
        }

        .left-line {
            width: 0%;
            height: 11.1%;
            left: 20%;
            bottom: 10%;
        }

        .right-line {
            width: 11.1%;
            height: 0%;
            left: 50%;
            bottom: 10%;
        }

        &.appearing {
            .left-line {
                animation: drawleft calc(2 * $nav-animation-length) forwards;
            }

            .right-line {
                animation: drawright calc(2 * $nav-animation-length) forwards;
            }
        }

        &.disappearing {
            .left-line {
                animation: eraseleft calc(2 * $nav-animation-length) forwards;
            }

            .right-line {
                animation: eraseright calc(2 * $nav-animation-length) forwards;
            }
        }

        &.red-cross {
            .turn-box {
                transform: rotate(45deg);
            }

            .left-line,
            .right-line {
                background-color: $warning;
            }

            .left-line {
                width: 0%;
                height: 11.1%;
                left: 0%;
                bottom: 44.4%;
            }

            .right-line {
                width: 11.1%;
                height: 0%;
                left: 44.4%;
                bottom: 0%;
            }

            &.appearing {
                .left-line {
                    animation: drawcrossleft calc(2 * $nav-animation-length) forwards;
                }

                .right-line {
                    animation: drawcrossright calc(2 * $nav-animation-length) forwards;
                }
            }

            &.disappearing {
                .left-line {
                    animation: erasecrossleft calc(2 * $nav-animation-length) forwards;
                }

                .right-line {
                    animation: erasecrossright calc(2 * $nav-animation-length) forwards;
                }
            }
        }
    }

    @media (hover: hover) and (pointer: fine) {
        .indicator-box:hover {
            cursor: pointer;

            .left-line,
            .right-line {
                background-color: $secondary;
            }
        }
    }
</style>
