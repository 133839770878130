<template>
    <div >
        <PageTitle class="page-title"/>
        <Navigation :menuLinks="menuLinks" />
        <ContentBox :scroll="true">
            <BlogPage :contentId="routeName" />
        </ContentBox>
    </div>
</template>

<script>
import { useRoute } from 'vue-router'
import PageTitle from '@/modules/navigation/page-title.vue'
import Navigation from '@/modules/navigation/navigation.vue'
import ContentBox from '@/components/content-box.vue'
import BlogPage from '@/modules/blog/blog-page.vue'

export default {
    name: 'BlogView',
    components: {
        PageTitle,
        Navigation,
        ContentBox,
        BlogPage
    },
    computed: {
        routeName() {
            return useRoute().name
        },
        menuLinks() {
            return this.$store.getters.blogPageMenuLinks()
        }
    }
}
</script>
