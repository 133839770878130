<template>
    <div id="event">
        <div v-if="isEventBasePage">
            <div v-if="isEventLoading">
                Die Veranstaltung wird geladen
            </div>
            <div v-else-if="eventLoadError">
               {{ eventLoadError }} 
            </div>
            <div v-else>
                <Announcement
                    v-for="announcement in event.announcements"
                    :key="announcement.title"
                    :announcement="announcement"
                />
            </div>
        </div>
        <div v-else>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import Announcement from '@/components/announcement.vue'

export default {
    name: 'Event',
    components: {
        Announcement
    },
    props: {
        eventId: Number
    },
    computed: {
        route() {
            return this.$store.getters.route()
        },
        event() {
            return this.$store.getters.event()
        },
        isEventBasePage() {
            return this.route.name === 'event'
        },
        isEventLoading() {
            return this.$store.getters.isEventLoading()
        },
        eventLoadError() {
            return this.$store.getters.eventLoadError()
        }
    },
    created() {
        const eventId = this.eventId || this.route.params.eventId
        this.$store.dispatch('loadEvent', { eventId })
            .then(() => {
                this.setNavigation()
            })
    },
    watch: {
        route() {
            this.setNavigation()
        }
    },
    methods: {
        setNavigation() {
            const event = this.event
            if (event) {
                if (this.route.name === 'event') {
                    this.$store.dispatch('setViewTitle', event.name)
                }
                if (event.routes) {
                    this.$store.dispatch('loadSubMenuLinks', { event })
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    #event {
        width: 100%;
    }
</style>
