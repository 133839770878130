<template>
    <div id="expenses">
        <div v-if="isExpensesLoading">
            Lade Spesenbilanz...
        </div>
        <div v-else-if="expensesLoadError">
            {{ expensesLoadError }}
        </div>
        <div v-else-if="expensesProgressState === 'preparation'" class="expenses-state-message">
            Die Spesenbilanz wird noch vorbereitet...
        </div>
        <div v-else-if="expensesProgressState === 'in progress'" class="expenses-state-message">
            Die Spesenbilanz wird noch ergänzt...
        </div>
        <div v-if="isExpensesLoaded && ['in progress', 'final'].includes(expensesProgressState)">
            <div class="tab-bar income-wages">
                <div class="tab"
                    v-for="wage in expenses.incomeWages"
                    :key="wage.name"
                    @click="() => setIncomeWage(wage)"
                    :class="{ active: wage.name === (incomeWage && incomeWage.name) }"
                >
                    {{ wage.name }}
                </div>
            </div>
            <div class="expenses-block">
                <div class="overview">
                    <div class="title">
                        erlebnisspezifische Spesen
                    </div>
                    <div class="total-costs">
                        {{ totalCostsEvent + expenses.currency }}
                    </div>
                </div>
                <div class="table">
                    <div class="table-head">
                        <div class="identifier-column">
                            Verbrauchsgüter
                        </div>
                        <div class="amount-column">
                            Menge
                        </div>
                        <div class="cost-column">
                            Preis
                        </div>
                        <div class="total-column">
                            Summe
                        </div>
                    </div>
                    <div class="table-body">
                        <div v-for="prop in expenses.eventProps"
                            :key="prop.name"
                            class="row"
                        >
                            <div class="identifier-column">
                                {{ prop.name }}
                            </div>
                            <div class="amount-column">
                                {{ prop.amount }}
                            </div>
                            <div class="cost-column">
                                {{ prop.price + expenses.currency }}
                            </div>
                            <div class="total-column">
                                {{ prop.amount * prop.price + expenses.currency }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table">
                    <div class="table-head">
                        <div class="identifier-column">
                            Arbeit
                        </div>
                        <div class="amount-column">
                            Stunden
                        </div>
                        <div class="cost-column">
                            Lohn
                        </div>
                        <div class="total-column">
                            Summe
                        </div>
                    </div>
                    <div class="table-body">
                        <div v-for="work in expenses.eventWorkingHours"
                            :key="work.task"
                            class="row"
                        >
                            <div class="identifier-column">
                                {{ work.task }}
                            </div>
                            <div class="amount-column">
                                {{ work.hours }}
                            </div>
                            <div class="cost-column">
                                {{ incomeWage.pay + expenses.incomeWageUnit }}
                            </div>
                            <div class="total-column">
                                {{ work.hours * incomeWage.pay + expenses.currency }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="expenses-block">
                <div class="overview">
                    <div class="title">
                        weiterentwickelnde Spesen
                    </div>
                    <div class="total-costs">
                        {{ totalCostsOrganisation + expenses.currency }}
                    </div>
                </div>
                <div class="table">
                    <div class="table-head">
                        <div class="identifier-column">
                            neue Ausstattung
                        </div>
                        <div class="amount-column">
                            Menge
                        </div>
                        <div class="cost-column">
                            Preis
                        </div>
                        <div class="total-column">
                            Summe
                        </div>
                    </div>
                    <div class="table-body">
                        <div v-for="prop in expenses.organisationProps"
                            :key="prop.name"
                            class="row"
                        >
                            <div class="identifier-column">
                                {{ prop.name }}
                            </div>
                            <div class="amount-column">
                                {{ prop.amount }}
                            </div>
                            <div class="cost-column">
                                {{ prop.price + expenses.currency }}
                            </div>
                            <div class="total-column">
                                {{ prop.amount * prop.price + expenses.currency }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table">
                    <div class="table-head">
                        <div class="identifier-column">
                            generische Arbeit
                        </div>
                        <div class="amount-column">
                            Stunden
                        </div>
                        <div class="cost-column">
                            Lohn
                        </div>
                        <div class="total-column">
                            Summe
                        </div>
                    </div>
                    <div class="table-body">
                        <div v-for="work in expenses.organisationWorkingHours"
                            :key="work.task"
                            class="row"
                        >
                            <div class="identifier-column">
                                {{ work.task }}
                            </div>
                            <div class="amount-column">
                                {{ work.hours }}
                            </div>
                            <div class="cost-column">
                                {{ incomeWage.pay + expenses.incomeWageUnit }}
                            </div>
                            <div class="total-column">
                                {{ work.hours * incomeWage.pay }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Expenses',
    props: {
    },
    components: {
    },
    data() {
        return {
            incomeWage: null
        }
    },
    computed: {
        route() {
            return this.$store.getters.route()
        },
        event() {
            return this.$store.getters.event()
        },
        expensesProgressState() {
            return this.event && this.event && this.event.expenses && this.event.expenses[0].state
        },
        isExpensesLoading() {
            return this.$store.getters.isExpensesLoading()
        },
        isExpensesLoaded() {
            return this.$store.getters.isExpensesLoaded()
        },
        expensesLoadError() {
            return this.$store.getters.expensesLoadError()
        },
        expenses() {
            return this.$store.getters.expenses()
        },
        totalCostsEvent() {
            let totalCosts = 0
            this.expenses.eventProps.forEach(prop => {
                totalCosts += prop.amount * prop.price
            })
            this.expenses.eventWorkingHours.forEach(work => {
                totalCosts += work.hours * this.incomeWage.pay
            })
            return totalCosts
        },
        totalCostsOrganisation() {
            let totalCosts = 0
            this.expenses.organisationProps.forEach(prop => {
                totalCosts += prop.amount * prop.price
            })
            this.expenses.organisationWorkingHours.forEach(work => {
                totalCosts += work.hours * this.incomeWage.pay
            })
            return totalCosts
        }
    },
    watch: {
        event(event) {
            if (event && this.$store.getters.isEventAccessLoaded() && !this.$store.getters.hasAccess('expenses')) {
                this.$router.push({ name: 'event', params: { eventId: event.eventId } })
            }
        }
    },
    created() {
        const eventId = this.eventId || this.route.params.eventId
        this.$store.dispatch('loadExpenses', { eventId })
            .then(() => {
                this.setIncomeWage(this.expenses.incomeWages.find(item => item.isDefault) || this.expenses.incomeWages[0])
            })
    },
    methods: {
        setIncomeWage(incomeWage) {
            this.incomeWage = incomeWage
        }
    }
}
</script>

<style lang="scss" scoped>
    #expenses {
        width: 100%;

        .expenses-state-message {
            margin-bottom: $view-padding;
        }

        .expenses-block {
            margin-bottom: $view-padding;

            .overview {
                border-bottom: $thin-line solid $primary;
                margin-bottom: $view-padding;
                display: flex;

                .title {
                    width: 670px;
                }

                .total-costs {
                    width: 120px;
                    text-align: right;
                }
            }

            .table {
                margin-bottom: $view-padding;
                font-size: $font-size-s;

                .identifier-column {
                    width: 430px;
                }

                .amount-column,
                .cost-column,
                .total-column {
                    width: 120px;
                    text-align: right;
                }
            }
        }

        .income-wages {
            justify-content: flex-start;
        }
    }
</style>
