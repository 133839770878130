<template>
    <div class="event-assigner"
        v-if="user"
    >
        <div v-if="events">
            <div class="events-table-container">
                <div class="table">
                    <div class="table-head">
                    </div>
                    <div class="table-body">
                        <div v-for="event in events" :key="event.id"
                            class="row event-item"
                            @click="() => assignEvent(event.eventId)"
                        >
                            <div class="event-name">{{ event.name }}</div>
                            <div class="event-date">{{ formatDate(event) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'EventAssigner',
    props: {
        user: null
    },
    components: {
    },
    computed: {
        events() {
            return (this.$store.getters.events() || []).filter(event => {
                const isFutureEvent = !event.date || event.date > new Date().getTime()
                return isFutureEvent
            })
        }
    },
    created() {
    },
    methods: {
        goBack() {
            this.$emit('done')
        },
        assignEvent(eventId) {
            this.$store.dispatch('registerParticipant', { username: this.user, eventId })
                .then(() => {
                    this.$emit('done')
                })
        },
        formatDate(event) {
            if (event && event.date) {
                const date = new Date(event.date)
                return date.toLocaleDateString() 
            }
            else {
                'Date unknown.'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .event-item {
        display: flex;

        &:hover {
            cursor: pointer;
            color: $secondary;
        }

        .event-name {
            width: 60%;
        }

        .event-date {
            width: 40%;
            text-align: right;
        }
    }
</style>
