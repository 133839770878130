<template>
    <div class="dialog-layer"
         v-bind:class="{ active: isOpen }">
        <div class="dialog-box">
            <slot/>
            <div class="button-bar">
                <div class="button" @click="onConfirm">YES</div>
                <div class="button" @click="onCancel">NO</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ConfirmationDialog',
        components: {
        },
        props: {
            isOpen: Boolean
        },
        data: function () {
            return {
            }
        },
        created() {
        },
        computed: {
        },
        methods: {
            onConfirm() {
                this.$emit('on-confirm')
            },
            onCancel() {
                this.$emit('on-cancel')
            }
        }
    }
</script>

<style scoped lang="scss">
    .dialog-layer {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.8);
        pointer-events: none;
        opacity: 0;
        transition: opacity 200ms;
    }

    .dialog-layer.active {
        opacity: 1;
        pointer-events: all;
    }

    .dialog-box {
        width: 500px;
        padding: $view-padding;
        background-color: $primary;
        color: $white;
        font-size: 2rem;
    }

    .button-bar {
        margin-top: $view-padding;
        display: flex;
        justify-content: flex-end;
    }

    .button {
        margin-left: $view-padding;
        cursor: pointer;
    }

    .button:hover {
        background-color: $primary;
        color: $secondary;
    }
</style>
