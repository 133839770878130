<template>
    <div class="menu-button"
        :class="{
            open: menuIsOpen,
            animated: animated,
            'got-animated': gotAnimated
        }"
        :style="{
            width: size + 'px', 
            height: size + 'px',
            'margin-top': margin + 'px',
            'margin-right': margin + 'px'
        }"
    >
        <div class="circle"
            :class="{ outstanding: isMenuButtonStandingOut }"
        >
        </div>
        <div class="stripe first" ></div>
        <div class="stripe second" ></div>
        <div class="stripe third" ></div>
    </div>
</template>

<script>

export default {
    name: 'MenuButton',
    components: {
    },
    props: {
        size: Number,
        margin: Number,
        animated: Boolean,
        menuIsOpen: Boolean
    },
    data: function () {
        return {
            gotAnimated: false
        }
    },
    computed: {
        isMenuButtonStandingOut() {
            return this.$store.getters.isMenuButtonStandingOut()
        }
    },
    watch: {
        animated(isSet) {
            if (isSet && !this.gotAnimated) {
                setTimeout(this.unsetInitialAnimation, 250)
            }
        }
    },
    methods: {
        unsetInitialAnimation() {
            this.gotAnimated = true
        }
    }
}
</script>

<style scoped lang="scss">
    @keyframes grow {
        from { width: 0%; }
        to { width: 100%; }
    }
    
    .menu-button {
        position: relative;
        margin-top: $menu-margin;
        margin-right: $menu-margin;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;

        .circle {
            position: absolute;
            top: -50%;
            left: -50%;
            width: 200%;
            height: 200%;
            border-radius:50%;
            background-color: $white;
            opacity: 0;
            -webkit-tap-highlight-color: transparent;

            &.outstanding {
                opacity: 0.9;
            }
        }

        .stripe {
            height: 11.1%;
            position: absolute;
            right: 0;
            background-color: $primary;

            &.first {
                top: 11.1%;
                transition: transform $nav-animation-length, top $nav-animation-length, background-color $nav-animation-length;
            }
            &.second {
                top: 44.4%;
                transition: width $nav-animation-length, right $nav-animation-length, background-color $nav-animation-length;
            }
            &.third {
                top: 77.7%;
                transition: transform $nav-animation-length, top $nav-animation-length, background-color $nav-animation-length;
            }
        }

        &.animated:not(.got-animated) {
            .stripe {
                animation: grow $nav-animation-length forwards;
            }
        }

        &.got-animated {
            .stripe {
                width: 100%;
                left: 0;
            }
        }
    }

    .menu-button.open {
        .circle {
            opacity: 0;
        }

        .stripe {
            background-color: $white;

            &.first {
                transform: rotate(-45deg);
                top: 50%;
            }
            &.second {
                width: 0%;
                background-color: transparent;
            }
            &.third {
                transform: rotate(45deg);
                top: 50%;
            }
        }
    }

    @media (hover: hover) and (pointer: fine) {
        .menu-button:hover {
            .stripe {
                background-color: $secondary;
            }
        }
    }
</style>