const FAVICONS = {};
const basePath = "";
FAVICONS.standard = location.origin + "/" + basePath + "/favicon_yellow" + ".ico";
FAVICONS.standardGrey = location.origin + "/" + basePath + "/favicon_grey" + ".ico";

export const responsiveStore = {
  state: {
    windowSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    breakpointBig: 1300,
    breakpointMedium: 900,
    menuMargin: 20,
    menuPadding: 20,
    navbarHeight: 50,
    navbarMargin: 20,
    titleBoxWidth: 200,
  },
  getters: {
    isBigScreen: (state) => () => state.windowSize.width >= state.breakpointBig,
    isMediumScreen: (state) => () =>
      state.windowSize.width > state.breakpointMedium &&
      state.windowSize.width < state.breakpointBig,
    isSmallScreen: (state) => () =>
      state.windowSize.width <= state.breakpointMedium,
    navbarHeight: (state) => () => state.navbarHeight,
    navbarMargin: (state) => () => state.navbarMargin,
    titleBoxWidth: (state) => () => state.titleBoxWidth,
  },
  mutations: {
    SET_WINDOW_SIZE: (state, payload) => (state.windowSize = payload),
    SET_NAVBAR_HEIGHT: (state, payload) => (state.navbarHeight = payload),
    SET_NAVBAR_MARGIN: (state, payload) => (state.navbarMargin = payload + 20),
    SET_TITLE_BOX_WIDTH: (state, payload) => (state.titleBoxWidth = payload),
  },
  actions: {
    onResize: ({ commit }, event) => {
      commit("SET_WINDOW_SIZE", {
        width: event.target.innerWidth,
        height: event.target.innerHeight,
      });
    },
    setNavbarSize: ({ commit }, event) => {
      commit("SET_NAVBAR_HEIGHT", event.navbarHeight);
      commit("SET_NAVBAR_MARGIN", event.navbarMargin);
      commit("SET_TITLE_BOX_WIDTH", event.titleBoxWidth);
    },
    setFavicon({ selection = "standard" }) {
      if (document.querySelector("link[rel='icon']") !== null) {
        document.querySelector("link[rel='icon']").remove();
      }
      if (document.querySelector("link[rel='shortcut icon']") !== null) {
        document.querySelector("link[rel='shortcut icon']").remove();
      }
      document
        .querySelector("head")
        .insertAdjacentHTML(
          "beforeend",
          '<link rel="icon" href="' +
            FAVICONS[selection] +
            '" type="image/gif">'
        );
    },
  },
};
