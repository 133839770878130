<template>
    <div id="profile">
        <div v-if="profileLoadError">
            {{ profileLoadError }}
        </div>
        <div v-else>
            <div class="field-set">
                <div>
                    Passwort (mind. 8 Zeichen)
                </div>
                <input type="password"
                    :value="newPassword || newPassword === '' ? newPassword : password"
                    @input="event => onEditPasswordField(event.target.value)"
                />
            </div>
            <div class="field-set"
                v-if="(newPassword || newPassword === '') && newPassword !== newPasswordCheck"
            >
                <div>
                    Passwortbestätigung
                </div>
                <input type="password"
                    :value="newPasswordCheck"
                    @input="event => onEditPasswordCheckField(event.target.value)"
                    placeholder="wiederhole zum Speichern"
                />
            </div>
            <div class="field-set">
                <div>
                    Email
                </div>
                <input :value="profile.email" @input="event => onEditProfileField('email', event.target.value)" />
            </div>
            <div class="field-set">
                <div>
                    Telefon
                </div>
                <input :value="profile.phone" @input="event => onEditProfileField('phone', event.target.value)" />
            </div>
            <div class="field-set">
                <div>
                    Abonniere den Newsletter
                </div>
                <ul>
                    <li :class="{ selected: profile.isNewsletterSubscribed }"
                        @click="event => onEditNewsLetterSubscription(true)"
                    >
                        &#x25BA;Ja
                    </li>
                    <li :class="{ selected: !profile.isNewsletterSubscribed }"
                        @click="event => onEditNewsLetterSubscription(false)"
                    >
                        &#x25BA;Nein
                    </li>
                </ul>
                <div class="validation" :class="{ active: isNewsletterSubscribedValidaton }">
                    {{ isNewsletterSubscribedValidaton }}
                </div>
            </div>
            <div class="field-set">
                <div>
                    Unterstütze MAER
                </div>
                <ul>
                    <li :class="{ selected: profile.isWillingToSupport }"
                        @click="event => onEditWillingToSupport(true)"
                    >
                        &#x25BA;Ja
                    </li>
                    <li :class="{ selected: !profile.isWillingToSupport }"
                        @click="event => onEditWillingToSupport(false)"
                    >
                        &#x25BA;Nein
                    </li>
                </ul>
                <div class="validation" :class="{ active: isWillingToSupportValidaton }">
                    {{ isWillingToSupportValidaton }}
                </div>
            </div>
            <div class="field-set" v-if="profile.isWillingToSupport">
                <div>
                    Was willst du machen?
                </div>
                <ul>
                    <li :class="{ selected: profile.isSupporterWithConcept }"
                        @click="event => onEditProfileField('isSupporterWithConcept', !profile.isSupporterWithConcept)"
                    >
                        &#x25BA;Konzept
                    </li>
                    <li :class="{ selected: profile.isSupporterWithProps }"
                        @click="event => onEditProfileField('isSupporterWithProps', !profile.isSupporterWithProps)"
                    >
                        &#x25BA;Ausstattung
                    </li>
                    <li :class="{ selected: profile.isSupporterWithPerformance }"
                        @click="event => onEditProfileField('isSupporterWithPerformance', !profile.isSupporterWithPerformance)"
                    >
                        &#x25BA;Eine Rolle spielen
                    </li>
                </ul>
            </div>
            <div class="field-set">
                <div>
                    Beantrage Datenlöschung
                </div>
                <button @click="event => onRequestDeletion()" class="deletion-button" :class="{ active: profile.isDueForDeletion }">
                    {{ deletionText }}
                </button>
            </div>
        </div>
        <div v-if="profileSaveError">
            {{ profileSaveError }}
        </div>
        <div v-else class="saving-state">
            {{ profileSavingState }}
        </div>
        <div class="bottom-spacer">
        </div>
    </div>
</template>

<script>

export default {
    name: 'Profile',
    props: {
    },
    components: {
    },
    data: function() {
        return {
            isTryingToSubscribeToNewsletter: false,
            isWillingToSupportValidaton: false,
            pleaseEnterContactString: 'Bitte gib mindestens eine Kontaktart an.',
            newPassword: null,
            newPasswordCheck: null
        }
    },
    computed: {
        route() {
            return this.$store.getters.route()
        },
        profileLoadError() {
            return this.$store.getters.profileLoadError()
        },
        profile() {
            return this.$store.getters.profile()
        },
        profileSavingState() {
            return this.$store.getters.profileSavingState()
        },
        profileSaveError() {
            return this.$store.getters.profileSaveError()
        },
        username() {
            return this.$store.getters.username()
        },
        password() {
            return this.$store.getters.password()
        },
        deletionText() {
            const isDueForDeletion = this.profile && this.profile.isDueForDeletion
            if (!isDueForDeletion) {
                return 'Ja, ich will alle meine Daten bei MAER gelöscht haben.'
            }
            else {
                return 'beauftragt: ' + new Date(isDueForDeletion).toLocaleDateString()
            }
        },
        isContactMethodFilled() {
            return (this.profile && typeof this.profile.email === 'string' && this.profile.email !== '') ||
                (this.profile && (typeof this.profile.phone === 'string' || typeof this.profile.phone === 'number') && this.profile.phone !== '')
        },
        isNewsletterSubscribedValidaton() {
            if (!this.isContactMethodFilled && (this.isTryingToSubscribeToNewsletter || (this.profile && this.profile.isNewsletterSubscribed))) {
                return this.pleaseEnterContactString
            }
            else {
                return false
            }
        }
    },
    created() {
        this.load()
        this.$store.dispatch('setSubViewTitle', this.username)
    },
    beforeUnmount() {
        this.$store.dispatch('setSubViewTitle', '')
    },
    methods: {
        load() {
            this.$store.dispatch('loadProfile')
        },
        onEditPasswordField(newPassword) {
            this.newPassword = newPassword
            this.onPasswordEdit()
        },
        onEditPasswordCheckField(newPassword) {
            this.newPasswordCheck = newPassword
            this.onPasswordEdit()
        },
        onPasswordEdit() {
            if (this.newPassword === this.newPasswordCheck) {
                if (this.newPassword.length > 7) {
                    this.$store.dispatch('savePassword', { password: this.newPassword })
                }
            }
        },
        onEditProfileField(field, value) {
            const profile = this.profile
            profile[field] = value
            this.$store.dispatch('editProfile', { profile })
        },
        onEditNewsLetterSubscription(doSubscribe) {
            if (doSubscribe) {
                this.isTryingToSubscribeToNewsletter = true
                if (this.isContactMethodFilled) {
                    this.onEditProfileField('isNewsletterSubscribed', true)
                }
            }
            else {
                this.isTryingToSubscribeToNewsletter = false
                this.onEditProfileField('isNewsletterSubscribed', false)
            }
        },
        onEditWillingToSupport(isWilling) {
            if (this.isContactMethodFilled) {
                this.onEditProfileField('isWillingToSupport', isWilling)
                this.isWillingToSupportValidaton = false
            }
            else if (isWilling) {
                this.isWillingToSupportValidaton = this.pleaseEnterContactString
            }
            else {
                this.onEditProfileField('isWillingToSupport', isWilling)
                this.isWillingToSupportValidaton = false
            }
        },
        onRequestDeletion() {
            const isDueForDeletion = this.profile && this.profile.isDueForDeletion
            if (!isDueForDeletion) {
                this.onEditProfileField('isDueForDeletion', new Date().getTime())
            }
            else {
                this.onEditProfileField('isDueForDeletion', false)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    #profile {
        width: 100%;

        .field-set {
            width: 100%;
            margin: 0;
            margin-bottom: $view-padding;

            button {
                width: 100%;
                text-align: left;

                &.active {
                    background-color: $primary;
                    color: $white;
                    border: $white;
                }
            }

            .validation {
                color: $secondary;
                display: none;

                &.active {
                    display: block;
                }
            }
        }

        .saving-state {
            color: $secondary;
            height: $view-padding;
        }

        .bottom-spacer {
            height: $botttom-spacer-height;
        }
    }
</style>
