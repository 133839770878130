<template>
    <div class="question" :class="{ locked: locked }">
        {{ question }}
        <ul v-if="isSelection">
            <li v-for="(option, index) in options"
                :key="index"
                :class="{ selected: answer && answer.includes(index) }"
                @click="event => onSelect(index)"
                 :disabled="locked"
            >
                &#x25BA;{{ option.text }}
            </li>
        </ul>
        <input v-else-if="isInput" :value="answer" @input="event => onInput(event.target.value)" :disabled="locked" />
        <textarea v-else-if="isTextarea" :value="answer" @input="event => onInput(event.target.value)" :disabled="locked" />
    </div>
</template>

<script>

export default {
    name: 'Question',
    props: {
        type: String,
        question: String,
        options: Array,
        answer: [String, Array],
        locked: Boolean
    },
    components: {
    },
    computed: {
        isSelection() {
            return this.type === 'selection'
        },
        isInput() {
            return this.type === 'input'
        },
        isTextarea() {
            return this.type === 'textarea' || this.type === 'textareaS'
        }
    },
    created() {
    },
    watch: {
    },
    methods: {
        onSelect(index) {
            this.$emit('filling', [index])
        },
        onInput(value) {
            this.$emit('filling', [value])
        }
    }
}
</script>

<style lang="scss" scoped>
    .question {
        width: 100%;
        margin: 0;
        margin-bottom: $view-padding;

        &.locked {
            pointer-events: none;
            user-select: none;
        }
    }
</style>
