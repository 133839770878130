function findItem(itemId, children) {
    let item
    children.forEach((child) => {
        if (child.id === itemId) {
            item = child
        } else if (!item && child.children) {
            item = findItem(itemId, child.children)
        }
    })
    return item
}

function getFreeItemId(children, highestId = 0) {
    let freeId = highestId
    children.forEach((child) => {
        if (child.id >= freeId) {
            freeId = child.id + 1
        }
        if (child.children) {
            freeId = getFreeItemId(child.children, freeId)
        }
    })
    return freeId
}

export {
    findItem,
    getFreeItemId
}