<template>
  <div>
    <Navigation
      :homeLinkRouteName="'info'"
      :homeLinkName="'MAER'"
      :menuLinks="internMenuLinks"
      :subMenuLinks="subMenuLinks"
    >
      <template v-slot:generic-nav-item-left-start>
        <ViewTitle/>
      </template>
    </Navigation>
    <ContentBox v-if="!route.meta.useFullWidth" :scroll="true">
      <router-view class="router-view-scroll-box"></router-view>
    </ContentBox>
    <div v-else class="full-size">
      <router-view class="router-view-scroll-box"></router-view>
    </div>
  </div>
</template>

<script>
import Navigation from '@/modules/navigation/navigation.vue'
import ViewTitle from '@/modules/navigation/view-title.vue'
import ContentBox from '@/components/content-box.vue'

export default {
  name: 'InternView',
  components: {
    Navigation,
    ViewTitle,
    ContentBox
  },
  computed: {
    route() {
      return this.$store.getters.route()
    },
    internMenuLinks() {
      return this.$store.getters.internMenuLinks()
    },
    subMenuLinks() {
      return this.$store.getters.subMenuLinks()
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.setViewTitle()
    })
  },
  beforeUnmount() {
    this.$store.dispatch('unloadIntern')
  },
  watch: {
    route() {
      this.setViewTitle()
    }
  },
  methods: {
    setViewTitle() {
      if (this.route) {
        this.$store.dispatch('setViewTitle', this.route.meta && this.route.meta.title)
        if (this.route.meta && this.route.meta.subTitle) {
          this.$store.dispatch('setSubViewTitle', this.route.meta && this.route.meta.subTitle)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .full-size {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
</style>