<template>
    <div class="blog-item">
        <img class="image"
            v-if="content && content.fileName && content.fileName != ''"
            :src="imagePath"
        >
        <div class="title"
            v-if="title && title != ''"
        >
            {{ title }}
        </div>
        <div class="text"
            v-if="text"
        >
            {{ text }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlogItem',
    props: {
        content: Object
    },
    components: {
    },
    mounted() {
    },
    computed:{
        title() {
            return this.content.title
        },
        imagePath() {
            return this.filesUrlPath + this.content.fileName
        },
        text() {
            return this.content.text
        },
        filesUrlPath() {
            return process.env.VUE_APP_FILES_URL + '/'
        }
    }, 
    methods: {
    }
}
</script>

<style lang="scss" scoped>
    .blog-item {
        width: 100%;
    }

    .image {
        margin-top: $blog-content-margin;
        width: 100%;
        height: auto;
    }

    .title {
        margin-top: $blog-item-margin;    
        font-size: 1.5rem;
    }

    .text {
        margin-top: $blog-item-margin;
        white-space: pre-line;
    }

    @media (max-width: 900px) {
        .title {
            font-size: 1rem;
        }
    }
</style>
