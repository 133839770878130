<template>
    <div class="window-box"
        :class="{ 'as-nav-z-index': isPageTitleAnimationDone }"
        ref="windowBox"
    >
        <div class="cover-box"
            :class="{
                hide: isPageTitleAnimationDone || isCoverBoxHidden,
                'outfaded': isPageTitleAnimationDone
            }"
        >
        </div>
        <div class="title-box"
            :class="{
                cornered: isPageTitleAnimationDone || !isTitleCentered,
                moved: isPageTitleAnimationDone,
                hidden: isPageTitleAnimationDone && disappear,
                show: show,
                'white-color': color === 'white'
            }"
            :style="{ width: titleBoxWidth, left: titleBoxLeft }"
            ref="titleBox"
            @click="onTitleClick"
        >
            <div class="letter"
                v-for="item in letters"
                :key="item.letter" 
                :ref="item.letter"
                :style="{ width: item.width, left: item.left }"
            >
                <div class="animate-box"
                    :class="item.letter.toLowerCase()"
                >
                    {{ item.letter }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PageTitle',
    components: {
    },
    props: {
        disappear: Boolean,
        show: {
            type: Boolean,
            default: true
        },
        color: String
    },
    data: function () {
        return {
            titleBoxWidth: 0,
            titleBoxLeft: 0,
            letters: [
                {
                    letter: 'M',
                    width: 'auto',
                    left: 0
                },
                {
                    letter: 'A',
                    width: 'auto',
                    left: 0
                },
                {
                    letter: 'E',
                    width: 'auto',
                    left: 0
                },
                {
                    letter: 'R',
                    width: 'auto',
                    left: 0
                }
            ],
            isTitleCentered: true,
            isCoverBoxHidden: false
        }
    },
    created: function () {
        this.$nextTick(function () {
            this.initScaling()
            if (this.isPageTitleAnimationDone) {
                this.fixTitle()
            }
        })  
    },
    computed: {
        isPageTitleAnimationDone() {
            return this.$store.getters.isPageTitleAnimationDone() || false
        }
    },
    methods: {
        initScaling: function () {
            const firstLetter = this.$refs[this.letters[0].letter][0]
            if (firstLetter.offsetWidth) {
                this.scale()
            }
            else {
                setTimeout(this.initScaling, 100)
            }
        },
        scale: function () {
            const windowWidth = this.$refs.windowBox.offsetWidth
            const textHeight = this.$refs[this.letters[0].letter][0].offsetHeight
            const letterHeight = textHeight * 0.67
            const letterTopSpacing = (textHeight - letterHeight) / 2
            let totalWidth = 2 * ( 20 + letterTopSpacing)
            let left = 20 + letterTopSpacing
            this.letters = this.letters.map(item => {
                const element = this.$refs[item.letter][0]
                totalWidth += element.offsetWidth
                return {
                    ...item,
                    width: element.offsetWidth + 'px'
                }
            })
            this.titleBoxWidth = totalWidth + 'px'
            this.titleBoxLeft = (windowWidth / 2 - totalWidth / 2) + 'px'
            this.letters = this.letters.map((item, index) => {
                if (index) {
                    left += parseInt(this.letters[index - 1].width)
                }
                return {
                    ...item,
                    left: left + 'px'
                }
            })
            this.$store.dispatch('setNavbarSize', {
                navbarHeight: letterHeight,
                navbarMargin: (textHeight - letterHeight) / 2,
                titleBoxWidth: totalWidth
            })
        },
        moveTitle: function () {
            this.isTitleCentered = false
            setTimeout(this.fixTitle, 1000)
        },
        fixTitle: function () {
            this.titleBoxLeft = 0
        },
        hideCoverBox: function () {
            this.isCoverBoxHidden = true
            setTimeout(this.markAnimationAsDone, 500)
        },
        markAnimationAsDone: function () {
            this.$store.commit('SET_IS_PAGE_TITLE_ANIMATION_DONE', true)
        },
        onTitleClick: function () {
            if (!this.isPageTitleAnimationDone && !this.isCoverBoxHidden) {
                this.moveTitle()
                setTimeout(this.hideCoverBox, 1000)
            }
            else if (this.$route.name !== 'start') {
                this.$router.push({ name: 'start' })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @keyframes fadein {
        from { opacity: 0; }
        to { opacity: 1; }
    }

    @keyframes fadeout {
        from { opacity: 1; }
        to { opacity: 0; }
    }

    @keyframes movetitle {
        100% {
            top: $navbar-base-margin;
            left: 0%;
        }
    }

    @keyframes movem {
        0% {
            position: absolute;
            top: 0vh;
            left: 0vw;
        }
        25% {
            top: 33vh;
        }
        50% {
            left: -10vw;
        }
        100% {
            position: absolute;
            top: 0vh;
            left: 0vw;
        }
    }

    @keyframes movee {
        0% {
            position: absolute;
            top: 0vh;
            left: 0vw;
        }
        25% {
            top: -10vh;
        }
        50% {
            left: 10vw;
        }
        100% {
            position: absolute;
            top: 0vh;
            left: 0vw;
        }
    }

    @keyframes mover {
        0% {
            position: absolute;
            top: 0vh;
            left: 0vw;
        }
        25% {
            top: 40vh;
        }
        50% {
            left: 60vw;
        }
        100% {
            position: absolute;
            top: 0vh;
            left: 0vw;
        }
    }

    @keyframes goprimary {
        99% {
            color: $white;
        }
        100% {
            color: $primary;
        }
    }

    @keyframes gowhite {
        99% {
            color: $white;
        }
        100% {
            color: $white;
        }
    }

    .window-box {
        position: fixed;

        &.as-nav-z-index {
            z-index: 10;
        }
    }

    .window-box,
    .cover-box {
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 100;
    }

    .cover-box {
        position: absolute;
        background-color: $black;
        -webkit-tap-highlight-color: transparent;
        animation-timing-function: ease-in-out;

        &.hide:not(.outfaded) {
            animation: fadeout 1s forwards;
        }

        &.outfaded {
            opacity: 0;
            height: 0;
        }
    }

    .title-box {
        position: absolute;
        top: 45%;
        pointer-events: all;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        animation-name: fadein;
        animation-duration: 2s;
        animation-timing-function: ease-in;
        z-index: 100;
        color: $white;
        opacity: 0;
        transition: opacity 2s;;
        
        &.show {
            opacity: 1;
        }

        &.cornered {
            animation-timing-function: ease-in-out;
            animation: movetitle 1s forwards;

            &:not(.moved) {
                .letter {
                    animation: goprimary 1s forwards;
                }
            }
        }

        &.cornered:not(.moved) {
            .animate-box.m {
                animation: movem 1s forwards;
            }

            .animate-box.a {
                animation: movea 1s forwards;
            }

            .animate-box.e {
                animation: movee 1s forwards;
            }

            .animate-box.r {
                animation: mover 1s forwards;
            }
        }

        &.moved {
            animation: none;
            top: $navbar-base-margin;
            left: 0%;
            color: $primary;
        }

        &.disappear {
            color: transparent;
        }

        &.white-color {
            &.moved {
                color: $white;
            }

            &.cornered:not(.moved) {
                .letter {
                    animation: gowhite 1s forwards;
                }
            }
        }
    }

    .letter {
        font-size: $page-title-font-size;
        font-weight: $boldFont;
        position: absolute;
        -webkit-tap-highlight-color: transparent;
    }
    
    .animate-box {
        -webkit-tap-highlight-color: transparent;
        animation-timing-function: ease-in;
    }

    @media (hover: hover) and (pointer: fine) {
        .title-box:hover {
            .letter {
                color: $secondary;
            }
        }
    }

    @media (max-width: 900px) {
        .window-box {
            position: absolute;
        }
        
        .letter {
            font-size: 8vw;
        }
    }
</style>
