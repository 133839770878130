<template>
    <div>
        <ContentItem
            :item="content"
            @on-select-item="selectItem"
            :selectedIdPath="selectedIdPath"
            @on-create-child="createChild"
            @on-delete-item="deleteItem"
        />
    </div>
</template>

<script>
    import ContentItem from '@/modules/blog-cms/content-item.vue'

    export default {
        name: 'ContentBrowser',
        components: {
            ContentItem
        },
        props: {
            content: Object,
            selectedIdPath: Array
        },
        data: function () {
            return {
            }
        },
        created() {
        },
        computed: {
        },
        methods: {
            selectItem({ selectedItem, selectedIdPath }) {
                this.$emit('on-select-item', { selectedItem, selectedIdPath })
            },
            createChild(idPath) {
                this.$emit('on-create-child', idPath)
            },
            deleteItem(itemIdPath) {
                this.$emit('on-delete-item', itemIdPath)
            }
        }
    }
</script>

<style scoped lang="scss">
    .browser-box {
        padding: 15px;
    }

    .browser-item {
        font-size: 2rem;
        cursor: pointer;
    }

    .main-item:hover,
    .sub-item:hover,
    .browser-item .selected {
        color: $secondary;
    }

    .sub-items {
        padding-left: $view-padding;
    }

    @media (max-width: 1000px) {
        .browser-item {
            font-size: 1.5rem;
        }
    }
</style>
