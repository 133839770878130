<template>
    <div v-if="selectedItem">
        <div class="content-saving-state">{{contentSavingState}}{{contentEditError}}</div>
        <div class="content-type-selector" v-if="selectedItem.availableContentTypes && selectedItem.availableContentTypes.length">
            <label>Choose content-type:</label>
            <div class="content-type"
                v-for="contentType in selectedItem.availableContentTypes"
                :key="contentType.contentType"
                @click="() => applyContentType(contentType.contentType)">
                {{contentType.title}}
            </div>
        </div>
        <div class="input-set" v-for="field in selectedItem.fields" :key="field.field">
            <label>{{field.label}}</label>
            <input class="text-input"
                   v-if="field.type === 'input'"
                   type="text"
                   :value="selectedItem[field.field]"
                   @input="(event) => onInput(field.field, event.target.value)">
            <textarea v-if="field.type === 'textarea'"
                      :class="{ small: field.size === 'small' }"
                      :value="selectedItem[field.field]"
                      @input="(event) => onInput(field.field, event.target.value)"></textarea>
            <div class="file-input"
                 v-if="field.type === 'file' || field.type === 'image' || field.type === 'video' || field.type === 'sound'">
                <input type="file"
                       :id="field.field + '-fileInput'"
                       style="display: none;"
                       @input="(event) => onFileSelect(event, field.field, event.target.value)">
                <div class="select-file-button"
                     @click="() => openFileInput(field.field + '-fileInput')">
                    {{selectedItem[field.field] || 'SELECT FILE'}}
                </div>
            </div>
            <img class="image-preview"
                 v-if="field.type === 'image' && selectedItem[field.field]"
                 :src="filesUrlPath + selectedItem[field.field]">
            <video class="video-preview"
                   autoplay loop
                   ref="video"
                   v-if="field.type === 'video' && selectedItem[field.field]">
                <source :src="filesUrlPath + selectedItem[field.field]" type="video/mp4">
            </video>
            <audio class="sound-preview"
                   v-if="field.type === 'sound' && selectedItem[field.field]"
                   controls
                   :src="filesUrlPath + selectedItem[field.field]"
                   type="audio/mp3">
            </audio>
            <div class="toggle-input"
                 v-if="field.type === 'toggle'">
                <div class="toggle off-toggle"
                     v-bind:class="{ active: !selectedItem[field.field] }"
                     @click="() => onInput(field.field, false)">
                    off
                </div>
                <div class="toggle on-toggle"
                     v-bind:class="{ active: selectedItem[field.field] }"
                     @click="() => onInput(field.field, true)">
                    on
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ContentForm',
        components: {
        },
        props: {
            selectedItem: Object,
            selectedIdPath: Array
        },
        data: function () {
            return {
            }
        },
        created() {
        },
        computed: {
            contentSavingState() {
                return this.$store.getters.getContentSavingState()
            },
            contentEditError() {
                return this.$store.getters.getContentEditError()
            },
            filesUrlPath() {
                return process.env.NODE_ENV === 'development' ? process.env.VUE_APP_FILES_URL_LOCAL + '/' : process.env.VUE_APP_FILES_URL_INTERN + '/'
            }
        },
        watch: {
            selectedItem() {
                this.resetVideo()
            }
        },
        methods: {
            applyContentType(contentType) {
                this.$store.dispatch('applyContentType', {
                    idPath: this.selectedIdPath,
                    contentType
                })
            },
            onInput(field, value) {
                this.$store.dispatch('editContent', {
                    itemId: this.selectedIdPath[this.selectedIdPath.length - 1],
                    field,
                    value
                })
            },
            openFileInput(id) {
                document.getElementById(id).click()
            },
            onFileSelect(event, field, value) {
                const file = event.target.files[0]
                this.$store.dispatch('uploadFile', {
                    file,
                    itemId: this.selectedIdPath[this.selectedIdPath.length - 1],
                    field,
                    value: value.split(/(\\|\/)/g).pop()
                })
            },
            resetVideo() {
                if (this.$refs.video && this.$refs.video[0]) {
                    this.$refs.video[0].pause()
                    this.$refs.video[0].load()
                    this.$refs.video[0].play()
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .content-saving-state {
        position: absolute;
        top: 15px;
        right: 15px;
        font-size: 1.5rem;
        color: $secondary;
    }

    .input-set {
        display: flex;
        flex-direction: column;
    }

    label {
        margin-bottom: 0.5rem;
    }

    label,
    .content-type,
    .text-input,
    .file-input {
        font-size: 1.5rem;
        display: block;
    }

    .file-input {
        display: flex;

        .select-file-button:hover {
            color: $secondary;
            cursor: pointer;
        }
    }

    .content-type,
    input,
    textarea,
    .file-input.image-preview,
    .file-input,
    .sound-preview {
        border: none;
        background-color: $primary;
        color: $white;
        margin-bottom: $view-padding;
    }

    .content-type:hover {
        color: $secondary;
        cursor: pointer;
    }

    textarea {
        min-height: 300px;

        &.small {
            min-height: 100px;
        }
    }

    .image-preview,
    .video-preview {
        align-self: flex-start;
        max-width: 100%;
    }

    .sound-preview {
        width: 100%;
        background-color: transparent;
    }

    .toggle-input {
        display: flex;
        font-size: 1.5rem;
        margin-bottom: $view-padding;

        .toggle {
            width: 48%;
            color: $white;
            text-align: center;
            background-color: $primary;
            //background-color: $primary-light;
            cursor: pointer;

            &:hover,
            &.active {
                color: $secondary;
            }

            &.off-toggle {
                margin-right: 4%;
            }
        }
    }

    @media (max-width: 1000px) {
        .content-saving-state,
        label,
        .content-type,
        .text-input,
        .file-input  {
            font-size: 1.2rem;
        }
    }
</style>
