<template>
	<div id="login-container">
		<div v-if="loginError" class="login-error">{{loginError}}</div>
		<form>
			<input v-model="username"
				ref="usernameInput"
				autocomplete="on"
			>
			<input v-model="password"
				type="password"
				ref="passwordInput"
				autocomplete="on"
			>
		</form>
		<button type="button"
			@click="tryLogin">
			login
		</button>
	</div>
</template>

<script>
	export default {
		name: 'Login',
		components: {
		},
		data() {
			return {
				username: '',
				password: ''
			}
		},
		created() {
			document.addEventListener('keyup', this.handleKey)
		},
		unmounted() {
			document.removeEventListener("keyup", this.handleKey)
		},
		computed: {
			loginError() {
				return this.$store.getters.getLoginError()
			}		
		},
		methods: {
			handleKey(event) {
				if (event.code === 'Enter') {
					if (document.activeElement === this.$refs.usernameInput) {
						this.$refs.passwordInput.focus()
					}
					else if (document.activeElement === this.$refs.passwordInput) {
						this.tryLogin()
					}
					else {
						this.$refs.usernameInput.focus()
					}
				}
			},
			tryLogin() {
				this.$store.dispatch('login', {
					username: this.username ,
					password: this.password
				})
				this.username = ''
				this.password = ''
			}
		},
		mounted() {
			this.$refs.usernameInput.focus()
		}
   }
</script>

<style scoped lang="scss">
	#login-container {
		position: fixed;
		height: 100%;
		width: 100%;
		top: 0;
		background-color: $primary;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		input {
			background-color: $white;
			color: $primary;

			&:hover {
				color: $primary;
			}
		}

		button {
			margin: 0;
			width: 256px;
			border-color: $white;
		}
	}

	#login-container > * {
		margin: 4px;
	}

	#login-container > form {
		display: flex;
		flex-direction: column;
		margin: 0;

		input {
			margin-bottom: 6px;
			width: 256px;

			&:hover {
				color: $secondary;
			}
		}
	}

	.login-error {
		color: $secondary;
	}
</style>