<template>
    <div class="event-survey-action">
        <div class="action-title">
            {{ action.title }}
        </div>
        <div class="tab-bar state-options">
            <div v-for="state in states" :key="state"
                class="tab state-option"
                :class="{ active: action.state === state }"
                @click="() => setSurveyState(state)"
            >
                {{ state }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'EventSurveyAction',
    props: {
        action: Object
    },
    components: {
    },
    data() {
        return {
            states: [
                'preparation',
                'open',
                'closed'
            ]
        }
    },
    computed: {
        isActionActive() {
            return this.$store.getters.isActionActive(this.action.actionId)
        }
    },
    created() {
    },
    methods: {
        setSurveyState(state) {
            this.$store.dispatch('saveSurveyState', {
                surveyId: this.action.surveyId,
                state
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .event-survey-action {
        width: 100%;
        display: flex;
        margin-bottom: $view-padding;
        font-size: $font-size;

        .action-title {
            width: 33%;
            text-align: left;
        }

        .state-options {
            width: 66%;
            justify-content: flex-end;
            margin-bottom: 0;

            .state-option {
                width: 33%;
                text-align: center;
            }
        }
    }
</style>
