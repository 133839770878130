import { createRouter, createWebHistory } from 'vue-router'
import { store } from '@/store/store.js'

import LoginView from '@/views/login.view.vue'
import InternView from '@/views/intern.view.vue'
import StartView from '@/views/start.view.vue'
import BlogView from '@/views/blog.view.vue'

import InternPage from '@/modules/intern/intern-page.vue'
import BlogCMS from '@/modules/blog-cms/blog-cms.vue'
import Users from '@/modules/users/users.vue'
import Profile from '@/modules/profile/profile.vue'
import Events from '@/modules/events/events.vue'
import Event from '@/modules/events/event.vue'
import EventSettings from '@/modules/events/event-settings.vue'
import Participants from '@/modules/events/participants.vue'
import Survey from '@/modules/survey/survey.vue'
import Expenses from '@/modules/events/expenses.vue'

const routes = [
    {
        path: '/',
        name: 'start',
        component: StartView,
        meta: {
            forwardsRoute: 'info',
            requiresLock: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/info',
        name: 'info',
        component: BlogView,
        meta: {
            backwardsRoute: 'start',
            forwardsRoute: 'products',
            requiresLock: true
        }
    },
    {
        path: '/products',
        name: 'products',
        component: BlogView,
        meta: {
            backwardsRoute: 'info',
            forwardsRoute: 'contact',
            isBlogWithChildren: true,
            requiresLock: true
        }
    },
    {
        path: '/contact',
        name: 'contact',
        component: BlogView,
        meta: {
            backwardsRoute: 'products',
            requiresLock: true
        }
    },
    {
        path: '/imprint',
        name: 'imprint',
        component: BlogView,
        meta: {
            requiresLock: true
        }
    },
    {
        path: '/intern/',
        name: 'intern',
        component: InternView,
        meta: {
            title: 'Intern',
            subTitle: null
        },
        children: [
            {
                path: '',
                name: 'internStart',
                component: InternPage
            },
            {
                path: 'blogcms',
                name: 'blogcms',
                component: BlogCMS,
                meta: {
                    useFullWidth: true,
                    title: 'Content',
                    subTitle: 'Editor'
                }
            },
            {
                path: 'events',
                name: 'events',
                component: Events,
                meta: {
                    title: 'Events'
                }
            },
            {
                path: 'profile',
                name: 'profile',
                component: Profile,
                meta: {
                    title: 'Profil'
                }
            },
            {
                path: 'event/:eventId/',
                name: 'event',
                component: Event,
                meta: {
                    title: 'Event'
                },
                children: [
                    {
                        path: 'settings',
                        name: 'eventSettings',
                        component: EventSettings,
                        meta: {
                            title: 'Einstellungen'
                        }
                    },
                    {
                        path: 'participants',
                        name: 'participants',
                        component: Participants,
                        meta: {
                            title: 'Teilnehmer'
                        }
                    },
                    {
                        path: 'survey/:surveyId',
                        name: 'survey',
                        component: Survey,
                        meta: {
                            title: 'Fragebogen'
                        }
                    },
                    {
                        path: 'expenses',
                        name: 'expenses',
                        component: Expenses,
                        meta: {
                            title: 'Spesenbilanz'
                        }
                    }
                ]
            },
            {
                path: 'users',
                name: 'users',
                component: Users,
                meta: {
                    title: 'Users'
                }
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(/*process.env.BASE_URL*/),
    routes,
    scrollBehavior: function () {
        window.scrollTo(0, 0);
    }
})

router.beforeEach((to, _, next) => {
    const internRoute = routes.find(route => route.name === 'intern')
    const internRoutes = internRoute ? [internRoute] : []
    const isRequireringLock = to.meta.requiresLock
    const isProtecting = store.getters.isProtecting()
    const isUnlocked = store.getters.isUnlocked()
    const findChildren = route => route && route.children && route.children.forEach(childRoute => {
        internRoutes.push(childRoute)
        findChildren(childRoute)
    })
    internRoutes.forEach(findChildren)
    const isTryingToOpenInternSite = to.name && internRoutes.find(route => route.name === to.name || route.path === to.path)
    store.dispatch('resetWindowScrollPosition')
    if (isTryingToOpenInternSite && !store.getters.hasValidToken()) {
        store.commit('SET_ROUTE', routes.find(route => route.name === 'login'))
        if (to.name !== 'login') {
            store.commit('SET_ROUTE_AFTER_LOGIN', to)
        }
        next({ name: 'login' })
    }
    else {
        if (isRequireringLock && !isProtecting && !isUnlocked) {
            store.commit('SET_IS_PROTECTING', true);
        }
        store.commit('SET_ROUTE', to);
        if (to.name !== 'login' && isTryingToOpenInternSite) {
            store.commit('SET_ROUTE_AFTER_LOGIN', to)
        }
        next()
    }
})

export default router
