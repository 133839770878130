export const uxStore = {
    state: {
        isPageTitleAnimationDone: false,
        windowScrollPosition: 0,
        isMenuOpen: false,
        isSomethingSaved: false,
        errorList: [] 
    },
    getters: {
        isPageTitleAnimationDone: state => () => state.isPageTitleAnimationDone,
        windowScrollPosition: state => () => state.windowScrollPosition,
        isMenuOpen: state => () => state.isMenuOpen,
        isSomethingSaved: state => () => state.isSomethingSaved,
        errorList: state => () => state.errorList
    },
    mutations: {
        SET_IS_PAGE_TITLE_ANIMATION_DONE: (state, payload) => state.isPageTitleAnimationDone = payload,
        SET_WINDOW_SCROLL_POSITION: (state, payload) => state.windowScrollPosition = payload,
        SET_IS_MENU_OPEN: (state, payload) => state.isMenuOpen = payload,
        SET_IS_SOMETHING_SAVED: (state, payload) => state.isSomethingSaved = payload,
        SET_ERROR_INTO_ERROR_LIST: (state, payload) => state.errorList.push(payload),
        EMPTY_ERROR_LIST: (state) => state.errorList = []
    },
    actions: {
        setMenuOpenState: ({ state, commit }, isOpen) => {
            const setMenuIsOpen = function() {
                commit('SET_IS_MENU_OPEN', isOpen)
            }
            const regainWindowScrollPosition = function() {
                window.scrollTo(0, parseInt(state.windowScrollPosition || '0'))
            }
            if (isOpen) {
                commit('SET_WINDOW_SCROLL_POSITION', window.scrollY)
                setTimeout(setMenuIsOpen, 250)
            }
            else {
                setMenuIsOpen()
                setTimeout(regainWindowScrollPosition, 1)
            }
        },
        resetWindowScrollPosition: () => {
            window.scrollTo(0, 0)
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        }
    }
}
