<template>
    <div class="event-expenses-action">
        <div class="action-title">
            {{ action.title }}
        </div>
        <div class="tab-bar state-options">
            <div v-for="state in states" :key="state"
                class="tab state-option"
                :class="{ active: action.state === state }"
                @click="() => setExpensesState(state)"
            >
                {{ state }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'EventExpensesAction',
    props: {
        action: Object
    },
    components: {
    },
    data() {
        return {
            states: [
                'preparation',
                'in progress',
                'final'
            ]
        }
    },
    computed: {
        isActionActive() {
            return this.$store.getters.isActionActive(this.action.actionId)
        }
    },
    created() {
    },
    methods: {
        setExpensesState(state) {
            this.$store.dispatch('saveExpensesState', { expensesId: this.action.expensesId, state })
        }
    }
}
</script>

<style lang="scss" scoped>
    .event-expenses-action {
        width: 100%;
        display: flex;
        font-size: $font-size;

        .action-title {
            width: 33%;
            text-align: left;
        }

        .state-options {
            width: 66%;
            justify-content: flex-end;
            margin-bottom: 0;

            .state-option {
                width: 33%;
                text-align: center;
            }
        }
    }
</style>
