import router from '@/router'
import axios from 'axios'

export const navigationStore = {
    state: {
        viewTitle: '',
        subViewTitle: '',
        isViewTitleActive: false,
        isSubViewTitleActive: false,
        menuLinks: {
            blogPages: [
                {
                    contentId: 'start',
                    routeName: 'start'
                },
                {
                    contentId: 'info',
                    routeName: 'info'
                },
                {
                    contentId: 'products',
                    routeName: 'products'
                },
                {
                    contentId: 'contact',
                    routeName: 'contact'
                },
                {
                    contentId: 'imprint',
                    routeName: 'imprint'
                },
                {
                    routeName: 'login',
                    title: 'Login'
                }
            ]
        },
        internMenuLinks: [],
        subMenuLinks: [],
        isMenuButtonStandingOut: true,
        isModalOpen: false,
        modalTitle: ''
    },
    getters: {
        viewTitle: state => () => state.viewTitle,
        subViewTitle: state => () => state.subViewTitle,
        isViewTitleActive: state => () => state.isViewTitleActive,
        isSubViewTitleActive: state => () => state.isSubViewTitleActive,
        blogPageMenuLinks: (state, getters) => () => {
            return state.menuLinks.blogPages.map(menuLink => ({
                title: menuLink.contentId && getters.getContentById(menuLink.contentId) && getters.getContentById(menuLink.contentId).title || '',
                ...menuLink
            }))
        },
        internMenuLinks: state => () => state.internMenuLinks,
        subMenuLinks: state => () => state.subMenuLinks,
        isMenuButtonStandingOut: state => () => state.isMenuButtonStandingOut,
        isModalOpen: state => () => state.isModalOpen,
        modalTitle: state => () => state.modalTitle
    },
    mutations: {
        SET_VIEW_TITLE: (state, payload) => state.viewTitle = payload ? payload : '',
        SET_SUB_VIEW_TITLE: (state, payload) => state.subViewTitle = payload ? payload : '',
        SET_IS_VIEW_TITLE_ACTIVE: (state, payload) => state.isViewTitleActive = payload,
        SET_IS_SUB_VIEW_TITLE_ACTIVE: (state, payload) => state.isSubViewTitleActive = payload,
        SET_INTERN_MENU_LINKS: (state, payload) => state.internMenuLinks = payload,
        SET_SUB_MENU_LINKS: (state, payload) => state.subMenuLinks = payload,
        SET_IS_MENU_BUTTON_STANDING_OUT: (state, payload) => state.isMenuButtonStandingOut = payload,
        SET_IS_MODAL_OPEN: (state, payload) => state.isModalOpen = payload,
        SET_MODAL_TITLE: (state, payload) => state.modalTitle = payload
    },
    actions: {
        loadNavigation: async ({ getters, commit }) => {
            return axios.get(getters.backendPath() + '/navigation')
                .then(response => {
                    const data = response.data
                    commit('SET_INTERN_MENU_LINKS', data && data.menuLinks)
                    return data;
                })
                .catch(error => {
                    const message = error.message || error.response.data.message
                    commit('SET_ERROR_INTO_ERROR_LIST', message)
                })
        },
        setViewTitle: ({ commit }, payload) => {
            commit('SET_VIEW_TITLE', payload)
            commit('SET_IS_VIEW_TITLE_ACTIVE', false)
        },
        setSubViewTitle: ({ commit }, payload) => {
            commit('SET_SUB_VIEW_TITLE', payload)
            commit('SET_IS_SUB_VIEW_TITLE_ACTIVE', false)
        },
        setIsViewTitleActive: ({ commit, getters }, payload) => {
            if (getters.subViewTitle() !== '') {
                commit('SET_IS_VIEW_TITLE_ACTIVE', payload)
                commit('SET_IS_SUB_VIEW_TITLE_ACTIVE', !payload)
            }
        },
        setIsSubViewTitleActive: ({ commit, getters }, payload) => {
            if (getters.subViewTitle() !== '') {
                commit('SET_IS_VIEW_TITLE_ACTIVE', !payload)
                commit('SET_IS_SUB_VIEW_TITLE_ACTIVE', payload)
            }
        },
        loadSubMenuLinks: ({ commit, getters }, payload) => {
            const { event } = payload
            const subMenuLinks = [
                {
                    routeName: 'event',
                    title: event.name,
                    params: {
                        eventId: event.eventId
                    }
                },
            ]
            event.routes.forEach(route => {
                let isValid = true
                if (route.params && route.params.restrictedTo) {
                    isValid = false
                    route.params.restrictedTo.forEach(restrictionAccess => {
                        if (getters.hasAccess(restrictionAccess)) {
                            isValid = true
                        }
                    })
                }
                if (isValid) {
                    subMenuLinks.push({
                        ...route,
                        params: {
                            ...route.params,
                            eventId: event.eventId
                        }
                    })
                }
            })
            commit('SET_SUB_MENU_LINKS', subMenuLinks)
        },
        unloadNavigation: ({ commit }) => {
            commit('SET_INTERN_MENU_LINKS', [])
        },
        unloadSubMenuLinks: ({ commit }) => {
            commit('SET_SUB_MENU_LINKS', [])
        },
        navigateBack: ({ getters, commit }) => {
            commit('SET_SURVEY_USER_DATA_USER_ID', null)
            commit('SET_SUB_VIEW_TITLE', null)
            router.push(getters.returnRoute())
        },
        setIsMenuButtonStandingOut: ({ commit }, payload) => {
            commit('SET_IS_MENU_BUTTON_STANDING_OUT', payload)
        },
        openModal: ({ commit }, payload) => {
            commit('SET_IS_MODAL_OPEN', true)
            commit('SET_MODAL_TITLE', payload.modalTitle)
        },
        closeModal: ({ commit }) => {
            commit('SET_IS_MODAL_OPEN', false)
        }
    }
}
