<template>
    <div class="blog-page">
        <div class="title"
            v-if="title && title != ''"
        >
            {{ title }}
        </div>
        <div class="text"
            v-if="text && text != ''"
        >
            {{ text }}
        </div>
        <div class="items"
            v-if="isBlogWithChildren"
        >
            <div v-for="(item, index) in items" :key="index">
                <BlogItem :content="item" />
            </div>
        </div>
        <div class="navigation">
            <div>
                <Arrow v-if="isPageTitleAnimationDone && backwardsRoute"
                    @click="routeBackwards"
                    :size="navbarHeight"
                    :angle="-90"
                    :animated="isPageTitleAnimationDone"
                />
            </div>
            <div>
                <Arrow v-if="isPageTitleAnimationDone && forwardsRoute"
                    @click="routeForwards"
                    :size="navbarHeight"
                    :angle="90"
                    :animated="isPageTitleAnimationDone"
                />
            </div>
        </div>
    </div>
</template>

<script>
import BlogItem from '@/modules/blog/blog-item.vue'
import Arrow from '@/components/arrow.vue'

export default {
    name: 'BlogPage',
    props: {
        contentId: String
    },
    components: {
        BlogItem,
        Arrow
    },
    computed:{
        content() {
            return this.$store.getters.getContentById(this.contentId) || {}
        },
        title() {
            return this.content.title
        },
        text() {
            return this.content.text
        },
        items() {
            return this.content.children || []
        },
        isBlogWithChildren() {
            return this.$route.meta.isBlogWithChildren
        },
        backwardsRoute() {
            return this.$route.meta.backwardsRoute
        },
        forwardsRoute() {
            return this.$route.meta.forwardsRoute
        },
        navbarHeight() {
            return this.$store.getters.navbarHeight()
        },
        isPageTitleAnimationDone() {
            return this.$store.getters.isPageTitleAnimationDone()
        }
    }, 
    methods: {
        routeBackwards() {
            if (this.$route.meta.backwardsRoute && this.$route.name !== this.$route.meta.backwardsRoute) {
                this.$router.push({ name: this.$route.meta.backwardsRoute })
            }
        },
        routeForwards() {
            if (this.$route.meta.forwardsRoute && this.$route.name !== this.$route.meta.forwardsRoute) {
                this.$router.push({ name: this.$route.meta.forwardsRoute })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .blog-page {
        width: 100%;
    }

    .title {
        margin-top: $blog-content-margin;
        font-size: 1.5rem;
    }

    .text {
        margin-top: $blog-content-margin;
        white-space: pre-line;
    }

    .navigation {
        margin-top: $blog-content-margin;
        margin-bottom: $blog-content-margin;
        display: flex;
        justify-content: space-between;
    }

    @media (max-width: 900px) {
        .title {
            font-size: 1rem;
        }
    }
</style>
