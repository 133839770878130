<template>
    <div class="announcement">
        <div class="title">
            {{ announcement.title }}
        </div>
        <div class="text-container">
            <template class="text"
                v-for="textElement in textElements"
            >
                {{ typeof textElement === 'string' ? textElement : '' }}
                <router-link v-if="textElement.type === 'link'"
                    :key="textElement.replaceText"
                    :to="{ name: textElement.routeName, params: { ...route.params, ...textElement.params } }"
                >
                    {{ textElement.replaceText }}
                </router-link>
            </template>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Announcement',
    props: {
        announcement: Object
    },
    components: {
    },
    computed: {
        route() {
            return this.$store.getters.route()
        },
        textElements() {
            const textElements = this.announcement.text.split('<maer>')
            textElements.forEach((textElement, index) => {
                const link = this.announcement.links && this.announcement.links.find(link => link.replaceText === textElement)
                if (link) {
                    textElements[index] = {
                        type: 'link',
                        ...link
                    }
                }
            })
            return textElements
        }
    },
    created() {
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
    .title {
        margin-bottom: $view-padding;
    }

    .text-container {
        margin-bottom: $view-padding;
        font-size: $font-size-s;

        a {
            display: inline-block;
            margin: 0;
            text-decoration: none;
            font-weight: $boldFont;
            color: $primary;

            &:active,
            &:visited,
            &:focus {
                color: $primary;
            }

            &:hover {
                color: $secondary;
            }
        }
    }
</style>
