import axios from 'axios'

export const expensesStore = {
    state: {
        isExpensesLoading: false,
        isExpensesLoaded: false,
        expensesLoadError: null,
        expenses: {},
        isExpensesStateSaving: false,
        isExpensesStateSaved: false,
        expensesStateSaveError: null
    },
    getters: {
        isExpensesLoading: state => () => state.isExpensesLoading,
        isExpensesLoaded: state => () => state.isExpensesLoaded,
        expensesLoadError: state => () => state.expensesLoadError,
        expenses: state => () => state.expenses,
        isExpensesStateSaving: state => () => state.isExpensesStateSaving,
        isExpensesStateSaved: state => () => state.isExpensesStateSaved,
        expensesStateSaveError: state => () => state.expensesStateSaveError
    },
    mutations: {
        SET_IS_EXPENSES_LOADING: (state, payload) => state.isExpensesLoading = payload,
        SET_IS_EXPENSES_LOADED: (state, payload) => state.isExpensesLoaded = payload,
        SET_EXPENSES_LOAD_ERROR: (state, payload) => state.expensesLoadError = payload,
        SET_EXPENSES: (state, payload) => state.expenses = payload,
        SET_IS_EXPENSES_STATE_SAVING: (state, payload) => state.isExpensesStateSaving = payload,
        SET_IS_EXPENSES_STATE_SAVED: (state, payload) => state.isExpensesStateSaved = payload,
        SET_EXPENSES_STATE_SAVE_ERROR: (state, payload) => state.expensesStateSaveError = payload
        
    },
    actions: {
        loadExpenses: async ({ getters, commit }, payload) => {
            commit('SET_IS_EXPENSES_LOADING', true)
            return axios.get(getters.backendPath() + '/event/' + payload.eventId + '/expenses')
                .then(response => {
                    const data = response.data
                    if (data.type && data.type === 'ERROR') {
                        commit('SET_EXPENSES', {})
                        commit('SET_EXPENSES_LOAD_ERROR', data.message)
                    }
                    else {
                        commit('SET_EXPENSES', data)
                        commit('SET_IS_EXPENSES_LOADED', true)
                        commit('SET_EXPENSES_LOAD_ERROR', null)
                    }
                    commit('SET_IS_EXPENSES_LOADING', false)
                    return data
                })
                .catch(error => {
                    commit('SET_IS_EXPENSES_LOADING', false)
                    commit('SET_EXPENSES_LOAD_ERROR', error.message || error.response.data.message)
                })
        },
        unloadExpenses: ({ commit }) => {
            commit('SET_EXPENSES', {})
            commit('SET_EXPENSES_LOAD_ERROR', null)
            commit('SET_IS_EXPENSES_LOADED', false)
        },
        saveExpensesState: async ({ getters, commit, dispatch }, payload) => {
            if (!getters.isExpensesStateSaving()) {
                const eventId = getters.event().eventId
                commit('SET_IS_EXPENSES_STATE_SAVING', true)
                return axios.post(getters.backendPath() + '/event/' + eventId + '/expenses/' + payload.expensesId + '/state',
                    { expensesState: payload.state }
                )
                    .then(response => {
                        const data = response.data
                        if (data.type && data.type === 'ERROR') {
                            commit('SET_EXPENSES_STATE_SAVE_ERROR', data.message)
                        }
                        else {
                            dispatch('loadEvent', { eventId })
                            commit('SET_IS_EXPENSES_STATE_SAVED', true)
                            commit('SET_EXPENSES_STATE_SAVE_ERROR', null)
                            commit('SET_IS_SOMETHING_SAVED', true)
                        }
                        commit('SET_IS_EXPENSES_STATE_SAVING', false)
                        return data
                    })
                    .catch(error => {
                        commit('SET_IS_EXPENSES_STATE_SAVING', false)
                        const message = error.message || error.response.data.message
                        commit('SET_EXPENSES_STATE_SAVE_ERROR', message)
                        commit('SET_ERROR_INTO_ERROR_LIST', message)
                    })
            }
        }
    }
}
