<template>
  <div id="app">
    <div v-if="isAvailable"
      class="app-container"
    >
      <StartLock />
      <router-view class="router-view" />
    </div>
    <NotAvailablePage v-else />
  </div>
</template>

<script>
import StartLock from '@/modules/start/start-lock.vue'
import NotAvailablePage from '@/modules/start/not-available-page.vue'

export default {
  name: 'App',
  components: {
    StartLock,
    NotAvailablePage,
  },
  data: () => {
    return {
      lastKeys: '',
      isAvailable: false
    }
  },
  created() {
    window.addEventListener('resize', this.readWindowSize)
    document.addEventListener('keyup', this.handleKey)
    if (this.isAvailable) {
      this.$store.dispatch('setFavicon', { selection: 'yellow' })
    }
  },
  mounted() {
    if (this.isAvailable) {
      this.$store.dispatch('loadContent')
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.readWindowSize)
    document.removeEventListener('keyup', this.handleKey)
  },
  methods: {
    readWindowSize(event) {
      this.$store.dispatch('onResize', event)
    },
    handleKey(event) {
      const newKey = event.code && event.code.replace(/Key/, '')
      if ('INTERN'.includes(this.lastKeys + newKey)) {
        this.lastKeys += newKey
      }
      else if ('MAERSINDMEHR'.includes(this.lastKeys + newKey)) {
        this.lastKeys += newKey
      }
      else {
        this.lastKeys = ''
      }
      if (this.lastKeys === 'INTERN') {
        this.$router.push({ name: 'internStart' })
        this.lastKeys = ''
      }
      if (this.lastKeys === 'MAERSINDMEHR') {
        this.lastKeys = ''
        this.isAvailable = true
        this.$store.dispatch('setFavicon', { selection: 'yellow' })
        this.$store.dispatch('loadContent')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  #app {
    height: 100%;
  }

  .app-container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .router-view {
    position: absolute;
    height: 100%;
  }

  .page-title {
    position: absolute;
    height: 100%;
  }
</style>
