import axios from 'axios'

export const usersStore = {
    state: {
        isUsersLoading: false,
        isUsersLoaded: false,
        usersLoadError: null,
        users: [],
        isUserLoading: false,
        isUserLoaded: false,
        userLoadError: null,
        user: {},
        isUserRolesLoading: false,
        isUserRolesLoaded: false,
        userRolesLoadError: null,
        userRoles: [],
        newUserSavingState: '',
        isNewUserSaving: false,
        isNewUserSaved: false,
        newUserSaveError: null
    },
    getters: {
        isUsersLoading: state => () => state.isUsersLoading,
        isUsersLoaded: state => () => state.isUsersLoaded,
        usersLoadError: state => () => state.usersLoadError,
        userLoadError: state => () => state.userLoadError,
        users: state => () => state.users,
        user: state => () => state.user,
        userRoles: state => () => state.userRoles,
        newUserSavingState: state => () => state.newUserSavingState,
        isNewUserSaving: state => () => state.isNewUserSaving,
        newUserSaveError: state => () => state.newUserSaveError
    },
    mutations: {
        SET_IS_USERS_LOADING: (state, payload) => state.isUsersLoading = payload,
        SET_IS_USERS_LOADED: (state, payload) => state.isUsersLoaded = payload,
        SET_USERS_LOAD_ERROR: (state, payload) => state.usersLoadError = payload,
        SET_USERS: (state, payload) => state.users = payload,
        SET_IS_USER_LOADING: (state, payload) => state.isUserLoading = payload,
        SET_IS_USER_LOADED: (state, payload) => state.isUserLoaded = payload,
        SET_USER_LOAD_ERROR: (state, payload) => state.userLoadError = payload,
        SET_USER: (state, payload) => state.user = payload,
        SET_IS_USER_ROLES_LOADING: (state, payload) => state.isUserRolesLoading = payload,
        SET_IS_USER_ROLES_LOADED: (state, payload) => state.isUserRolesLoaded = payload,
        SET_USER_ROLES_LOAD_ERROR: (state, payload) => state.userRolesLoadError = payload,
        SET_USER_ROLES: (state, payload) => state.userRoles = payload,
        SET_NEW_USER_SAVING_STATE: (state, payload) => state.newUserSavingState = payload,
        SET_IS_NEW_USER_SAVING: (state, payload) => state.isNewUserSaving = payload,
        SET_NEW_USER_SAVE_ERROR: (state, payload) => state.newUserSaveError = payload
    },
    actions: {
        loadUsers: async ({ getters, commit }) => {
            commit('SET_IS_USERS_LOADING', true)
            commit('SET_USERS_LOAD_ERROR', null)
            return axios.get(getters.backendPath() + '/users')
                .then(response => {
                    const data = response.data
                    commit('SET_USERS', data)
                    commit('SET_IS_USERS_LOADING', false)
                    commit('SET_IS_USERS_LOADED', true)
                    return data
                })
                .catch(error => {
                    commit('SET_IS_USERS_LOADING', false)
                    commit('SET_USERS_LOAD_ERROR', error.message || error.response.data.message)
                })
        },
        loadUser: async ({ getters, commit }, payload) => {
            commit('SET_IS_USER_LOADING', true)
            commit('SET_USER_LOAD_ERROR', null)
            return axios.get(getters.backendPath() + '/user/' + payload.userId)
                .then(response => {
                    const data = response.data
                    commit('SET_USER', data)
                    commit('SET_IS_USER_LOADING', false)
                    commit('SET_IS_USER_LOADED', true)
                    return data
                })
                .catch(error => {
                    commit('SET_IS_USER_LOADING', false)
                    commit('SET_USER_LOAD_ERROR', error.message || error.response.data.message)
                })
        },
        loadUserRoles: async ({ getters, commit }, payload) => {
            commit('SET_IS_USER_ROLES_LOADING', true)
            commit('SET_USER_ROLES_LOAD_ERROR', null)
            return axios.get(getters.backendPath() + '/event/' + payload.eventId + '/roles')
                .then(response => {
                    const data = response.data
                    commit('SET_USER_ROLES', data)
                    commit('SET_IS_USER_ROLES_LOADING', false)
                    commit('SET_IS_USER_ROLES_LOADED', true)
                    return data
                })
                .catch(error => {
                    commit('SET_USER_ROLES', [])
                    commit('SET_IS_USER_ROLES_LOADING', false)
                    commit('SET_USER_ROLES_LOAD_ERROR', error.message || error.response.data.message)
                })
        },
        unloadUsers: ({ commit }) => {
            commit('SET_USERS', [])
            commit('SET_IS_USERS_LOADED', false)
        },
        unloadUser: ({ commit }) => {
            commit('SET_USER', {})
            commit('SET_IS_USER_LOADED', false)
        },
        unloadUserRoles: ({ commit }) => {
            commit('SET_USER_ROLES', [])
            commit('SET_IS_USER_ROLES_LOADED', false)
        },
        saveNewUser: ({ commit, getters, dispatch }, payload) => {
            commit('SET_NEW_USER_SAVING_STATE', 'SAVING')
            commit('SET_IS_NEW_USER_SAVING', true)
            return axios.post(
                getters.backendPath() + '/users/newUser',
                { newUser: payload.newUser }
            )
                .then(response => {
                    commit('SET_IS_NEW_USER_SAVING', false)
                    const data = response.data
                    if (data.type && data.type === 'ERROR') {
                        commit('SET_NEW_USER_SAVE_ERROR', data.error || data.message)
                        commit('SET_NEW_USER_SAVING_STATE', '')
                    }
                    else {
                        commit('SET_NEW_USER_SAVING_STATE', 'SAVED')
                        commit('SET_NEW_USER_SAVE_ERROR', null)
                        commit('SET_IS_SOMETHING_SAVED', true)
                        dispatch('loadUsers')
                        return { isNewUserSaved: true }
                    }
                }, response => {
                    commit('SET_IS_NEW_USER_SAVING', false)
                    let message = response.data && response.data.message
                    if (response.data && response.data.status === 401) {
                        message = 'no valid login'
                    }
                    commit('SET_NEW_USER_SAVE_ERROR', message)
                    commit('SET_ERROR_INTO_ERROR_LIST', message)
                })
        }
    }
}
