import { createStore } from 'vuex'

import { accessStore } from '@/store/access.store.js'
import { actionsStore } from '@/store/actions.store.js'
import { authentificationStore } from '@/store/authentification.store.js'
import { contentStore } from '@/store/content.store.js'
import { internStore } from '@/store/intern.store.js'
import { profileStore } from '@/store/profile.store.js'
import { eventsStore } from '@/store/events.store.js'
import { expensesStore } from '@/store/expenses.store.js'
import { usersStore } from '@/store/users.store.js'
import { navigationStore } from '@/store/navigation.store.js'
import { participantsStore } from '@/store/participants.store.js'
import { responsiveStore } from '@/store/responsive.store.js'
import { surveyStore } from '@/store/survey.store.js'
import { uxStore } from '@/store/ux.store.js'
import { useRoute } from 'vue-router'

export const store = createStore({
	state: {
		route: null,
		routeAfterLogin: { name: 'internStart' }
	},
	getters: {
		route: state => () => state.route || useRoute(),
		routeAfterLogin: state => () => state.routeAfterLogin,
		backendPath: state => () => {
			const route = useRoute()
			const locationSubRouteDepth = window.location.pathname ? window.location.pathname.split('/').length : 0
			let subRouteDepth = 0
			if (route && route.path) {
				subRouteDepth = route.path.split('/').length
			}
			else if (state.route) {
				subRouteDepth = state.route.path.split('/').length
			}
			if (subRouteDepth < locationSubRouteDepth) {
				subRouteDepth = locationSubRouteDepth
			}
			return '../'.repeat(subRouteDepth) + process.env.VUE_APP_BACKEND_URL
		}
	},
	mutations: {
		SET_ROUTE(state, route) {
			state.route = route
		},
		SET_ROUTE_AFTER_LOGIN(state, route) {
			state.routeAfterLogin = route
		}
	},
	modules: {
		access: accessStore,
		actions: actionsStore,
		authentification: authentificationStore,
		content: contentStore,
		events: eventsStore,
		expenses: expensesStore,
		intern: internStore,
		navigation: navigationStore,
		participants: participantsStore,
		profile: profileStore,
		responsive: responsiveStore,
		survey: surveyStore,
		users: usersStore,
		ux: uxStore
	}
})
