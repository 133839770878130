<template>
    <div class="video-box"
        :class="{ centered: isCentered }"
    >
        <div class="center-box">
            <video class="preview-video" ref="videoPreview" preload="auto" playsinline>
                Your browser does not support this video.
            </video>
            <video class="playing-video" ref="multiVideo" preload="auto" autoplay="" muted="" playsinline>
                Your browser does not support this video.
            </video>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MultiVideo',
        components: {
        },
        props: {
            sources: Array,
            isCentered: Boolean
        },
        mounted() {
            this.$refs.multiVideo.addEventListener('ended', this.switchToNextVideo, false)
            this.playSource()
        },
        data: function () {
            return {
                recentSourceIndex: 0
            }
        },
        computed: {
        },
        watch: {
            sources() {
                this.playSource()
            }
        },
        methods: {
            playSource() {
                const videoElement = this.$refs.multiVideo
                const videoPreviewElement = this.$refs.videoPreview
                videoElement.setAttribute('src', this.sources[this.recentSourceIndex])
                videoElement.autoplay = true
                videoElement.load()
                const playPromise = videoElement.play()
                if (playPromise !== undefined) {
                    playPromise.then(() => {
                        const nextSourceIndex = this.recentSourceIndex === this.sources.length - 1 ? 0 : this.recentSourceIndex + 1
                        videoPreviewElement.setAttribute('src', this.sources[nextSourceIndex])
                    }).catch(() => {
                        videoElement.muted = true
                        videoElement.play()
                    })
                }
            },
            switchToNextVideo() {
                this.recentSourceIndex += 1
                if (this.recentSourceIndex === this.sources.length) {
                    this.recentSourceIndex = 0
                }
                this.playSource()
            }
        }
    }
</script>

<style scoped lang="scss">
    .video-box {
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;

        &.centered {
            justify-content: center;
        }

        .center-box {
            height: 100%;
            flex-grow: 1;

            video {
                position: relative;
                height: 100%;
                min-width: 100%;

                &.playing-video {
                    top: -101%;
                }
            }
        }
    }
</style>
