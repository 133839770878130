import axios from 'axios'

export const eventsStore = {
    state: {
        isEventsLoading: false,
        isEventsLoaded: false,
        eventsLoadError: null,
        events: [],
        isEventLoading: false,
        isEventLoaded: false,
        eventLoadError: null,
        event: {},
    },
    getters: {
        isEventsLoading: state => () => state.isEventsLoading,
        isEventsLoaded: state => () => state.isEventsLoaded,
        events: state => () => state.events,
        isEventLoading: state => () => state.isEventLoading,
        eventLoadError: state => () => state.eventLoadError,
        event: state => () => state.event
    },
    mutations: {
        SET_IS_EVENTS_LOADING: (state, payload) => state.isEventsLoading = payload,
        SET_IS_EVENTS_LOADED: (state, payload) => state.isEventsLoaded = payload,
        SET_EVENTS_LOAD_ERROR: (state, payload) => state.eventsLoadError = payload,
        SET_EVENTS: (state, payload) => state.events = payload,
        SET_IS_EVENT_LOADING: (state, payload) => state.isEventLoading = payload,
        SET_IS_EVENT_LOADED: (state, payload) => state.isEventLoaded = payload,
        SET_EVENT_LOAD_ERROR: (state, payload) => state.eventLoadError = payload,
        SET_EVENT: (state, payload) => state.event = payload
    },
    actions: {
        loadEvents: async ({ getters, commit }) => {
            commit('SET_IS_EVENTS_LOADING', true)
            return axios.get(getters.backendPath() + '/events')
                .then(response => {
                    const data = response.data
                    commit('SET_EVENTS', data)
                    commit('SET_IS_EVENTS_LOADING', false)
                    commit('SET_IS_EVENTS_LOADED', true)
                    return data
                })
                .catch(error => {
                    commit('SET_IS_EVENTS_LOADING', false)
                    const message = error.message || error.response.data.message
                    commit('SET_EVENTS_LOAD_ERROR', message)
                    commit('SET_ERROR_INTO_ERROR_LIST', message)
                })
        },
        loadEvent: async ({ getters, commit, dispatch }, payload) => {
            dispatch('unloadEvent')
            commit('SET_IS_EVENT_LOADING', true)
            await dispatch('loadUserRoles', payload)
            return axios.get(getters.backendPath() + '/event/' + payload.eventId)
            .then(async response => {
                const data = response.data
                commit('SET_EVENT', data)
                commit('SET_IS_EVENT_LOADING', false)
                commit('SET_IS_EVENT_LOADED', true)
                commit('SET_EVENT_LOAD_ERROR', null)
                dispatch('initActions')
                await dispatch('loadEventAccess')
                return data
            })
            .catch(error => {
                commit('SET_IS_EVENT_LOADING', false)
                commit('SET_EVENT_LOAD_ERROR', error.response.data.message)
            })
        },
        unloadEvents: async ({ commit }) => {
            commit('SET_EVENTS', [])
            commit('SET_IS_EVENTS_LOADED', false)
        },
        unloadEvent: async ({ commit, dispatch }) => {
            commit('SET_EVENT', {})
            commit('SET_IS_EVENT_LOADED', false)
            dispatch('clearActions')
        }
    }
}
