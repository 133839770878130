import router from '@/router';
import axios from 'axios'

const TOKEN_EXPIRATION_DELAY = 1000 * 60 * 60
let username = ''
let token = ''

axios.interceptors.request.use(function (config) {
    return {
		...config,
		headers: {
			...config.headers,
			Authorization: token,
			username: username
		}
	};
}, function (error) {
    return Promise.reject(error);
});

export const authentificationStore = {
	state: {
		username: '',
		password: null,
		token: '',
		loginError: null,
		isUserOpened: false,
		adminAuthorization: {
			username: null,
			token: null
		},
		returnRoute: null,
		isProtecting: false,
		isUnlocked: false,
	},
	getters: {
		hasValidToken: (state, getters) => () => {
			const route = getters.route()
			if (!state.token && (route && route.name) !== 'login') {
				router.push({ name: 'login' })
			}	
			return !!state.token
		},
		username: state => () => state.username,
		password: state => () => state.password,
		token: state => () => state.token,
		getLoginError: state => () => state.loginError,
		isUserOpened: state => () => state.isUserOpened,
		adminAuthorization: state => () => state.adminAuthorization,
		returnRoute: state => () => state.returnRoute,
		isProtecting: state => () => state.isProtecting,
		isUnlocked: state => () => state.isUnlocked,
	},
	mutations: {
		SET_USERNAME: (state, payload) => {
			state.username = payload
			username = payload
		},
		SET_PASSWORD: (state, payload) => {
			state.password = payload
		},
		SET_TOKEN: (state, payload) => {
			state.token = payload
			token = payload
		},
		SET_LOGIN_ERROR: (state, payload) => state.loginError = payload,
		SET_IS_USER_OPENED: (state, payload) => state.isUserOpened = payload,
		SET_ADMIN_AUTHORIZATION: (state, payload) => state.adminAuthorization = payload,
		SET_RETURN_ROUTE: (state, payload) => state.returnRoute = payload,
		SET_IS_PROTECTING: (state, payload) => state.isProtecting = payload,
		SET_IS_UNLOCKED: (state, payload) => state.isUnlocked = payload,
	},
	actions: {
		login: async ({ getters, commit, dispatch }, payload) => {
			const routeAfterLogin = getters.routeAfterLogin()
			return axios.post(getters.backendPath() + '/token', payload)
				.then(response => {
					if (response.data && !response.data.token) {
						commit('SET_LOGIN_ERROR', 'server error')
						return
					}
					commit('SET_USERNAME', payload.username)
					commit('SET_PASSWORD', payload.password)
					dispatch('setToken', response.data.token)
					dispatch('loadUserSpecificData')
					commit('SET_IS_PAGE_TITLE_ANIMATION_DONE', true)
					if (!payload.page) {
						router.push(routeAfterLogin)
					} else {
						router.push({ name: payload.page })
					}
				})
				.catch(error => {
					console.dir(error)
					if (error.response.status === 401) {
						commit('SET_LOGIN_ERROR', 'no valid login')
					}
					else if (error.response.status === 429) {
						commit('SET_LOGIN_ERROR', 'too many invalid sequent requests. please wait 5min')
					}
					else {
						commit('SET_LOGIN_ERROR', error.response.data.message)
					}
				})
		},
		setToken({ commit, dispatch }, payload) {
			commit('SET_TOKEN', payload)
			dispatch('setTokenExpiration')
		},
		setTokenExpiration({ commit, getters }) {
			setTimeout(() => {
				commit('SET_TOKEN', null)
				const route = getters.route()
				if (route.name !== 'login') {
					commit('SET_ROUTE_AFTER_LOGIN', route)
				}
				router.push({ name: 'login' })
			}, TOKEN_EXPIRATION_DELAY)
		},
		loadUserSpecificData({ dispatch }) {
			dispatch('loadNavigation')
			dispatch('loadAccess')
		},
		async logto({ getters, commit, dispatch }, payload) {
			if (getters.hasAccess('openUser')) {
				commit('SET_ADMIN_AUTHORIZATION', {
					token: getters.token(),
					username: getters.username()
				})
				commit('SET_RETURN_ROUTE', { ...payload.returnRoute })
				return axios.post(getters.backendPath() + '/logto', { username: payload.username })
					.then(response => {
						if (response.data && !response.data.token) {
							commit('SET_LOGIN_ERROR', 'server error')
							return
						}
						commit('SET_IS_USER_OPENED', true)
						commit('SET_USERNAME', payload.username)
						dispatch('setToken', response.data.token)
						dispatch('loadUserSpecificData').then(() => {
							router.push(payload.routeAfter)
						})
					})
					.catch(error => {
						console.dir(error)
						if (error.response.status === 401) {
							commit('SET_LOGIN_ERROR', 'you have no access to login as another user')
						}
						else {
							commit('SET_LOGIN_ERROR', error.response.data.message)
						}
					})
			}
		},
		async logback({ getters, commit, dispatch }) {
			const adminAuthorization = getters.adminAuthorization()
			if (getters.isUserOpened() && adminAuthorization && adminAuthorization.username && adminAuthorization.token) {
				commit('SET_IS_USER_OPENED', false)
				commit('SET_USERNAME', adminAuthorization.username)
				dispatch('setToken', adminAuthorization.token)
				dispatch('loadUserSpecificData').then(() => {
					router.push(getters.returnRoute())
				})
			}
		}
	}
}
