<template>
    <div class="participant-access">
        <div class="tab-bar access-options">
            <div v-for="option in accessOptions"
                :key="option"
                class="tab access-option"
                :class="{ active: participant.access && participant.access.includes(option) }"
                @click="() => toggleAccess(option)"
            >
                {{ option }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ParticipantAccess',
    props: {
        participant: Object
    },
    components: {
    },
    computed: {
        accessOptions() {
            return this.$store.getters.accessOptions()
        }
    },
    created() {
    },
    watch: {
    },
    methods: {
        toggleAccess(accessOption) {
            this.$store.dispatch('editParticipantAccess', { userId: this.participant.userId, accessName: accessOption } )
        }
    }
}
</script>

<style lang="scss" scoped>
    .participant-access {
        width: 100%;
        margin: 0;
        margin-top: -2px;

        .access-options {
            justify-content: flex-start;
            margin-bottom: 0;

            .access-option {
                flex-grow: 1;
                text-align: center;
                cursor: pointer;
                font-size: $font-size-s;
                line-height: $text-line-height;
            }
        }
    }
</style>
