import axios from 'axios'

export const accessStore = {
    state: {
        isAccessLoaded: false,
        access: [],
        isAccessOptionsLoading: false,
        isAccessOptionsLoaded: false,
        accessOptionsLoadError: null,
        accessOptions: [],
        isEventAccessLoading: false,
        isEventAccessLoaded: false,
        eventAccessLoadError: null,
        eventAccess: [],
        isSavingParticipantAccess: false,
        isParticipantAccessSaved: false,
        participantAccessSaveError: null,
        isEventRoleAccessDefaultsLoading: false,
        isEventRoleAccessDefaultsLoaded: false,
        eventRoleAccessDefaultsLoadError: null,
        eventRoleAccessDefaults: {},
    },
    getters: {
        isAccessLoaded: state => () => state.isAccessLoaded,
        access: state => () => state.access,
        hasAccess: state => (accessName) => {
            return state && state.access.find(accessItem => accessItem === accessName) || state.eventAccess.find(accessItem => accessItem === accessName)
        },
        isAccessOptionsLoading: state => () => state.isAccessOptionsLoading,
        isAccessOptionsLoaded: state => () => state.isAccessOptionsLoaded,
        accessOptionsLoadError: state => () => state.accessOptionsLoadError,
        accessOptions: state => () => state.accessOptions,
        isEventAccessLoading: state => () => state.isEventAccessLoading,
        isEventAccessLoaded: state => () => state.isEventAccessLoaded,
        eventAccessLoadError: state => () => state.eventAccessLoadError,
        eventAccess: state => () => state.eventAccess,
        isSavingParticipantAccess: state => () => state.isSavingParticipantAccess,
        isParticipantAccessSaved: state => () => state.isParticipantAccessSaved,
        participantAccessSaveError: state => () => state.participantAccessSaveError,
        isEventRoleAccessDefaultsLoading: state => () => state.isEventRoleAccessDefaultsLoading,
        isEventRoleAccessDefaultsLoaded: state => () => state.isEventRoleAccessDefaultsLoaded,
        eventRoleAccessDefaultsLoadError: state => () => state.eventRoleAccessDefaultsLoadError,
        eventRoleAccessDefaults: state => () => state.eventRoleAccessDefaults
    },
    mutations: {
        SET_IS_ACCESS_LOADED: (state, payload) => state.isAccessLoaded = payload,
        SET_ACCESS: (state, payload) => state.access = payload,
        SET_IS_ACCESS_OPTIONS_LOADING: (state, payload) => state.isAccessOptionsLoading = payload,
        SET_IS_ACCESS_OPTIONS_LOADED: (state, payload) => state.isAccessOptionsLoaded = payload,
        SET_ACCESS_OPTIONS_LOAD_ERROR: (state, payload) => state.accessOptionsLoadError = payload,
        SET_ACCESS_OPTIONS: (state, payload) => state.accessOptions = payload,
        SET_IS_EVENT_ACCESS_LOADING: (state, payload) => state.isEventAccessLoading = payload,
        SET_IS_EVENT_ACCESS_LOADED: (state, payload) => state.isEventAccessLoaded = payload,
        SET_EVENT_ACCESS_LOAD_ERROR: (state, payload) => state.eventAccessLoadError = payload,
        SET_EVENT_ACCESS: (state, payload) => state.eventAccess = payload,
        SET_IS_SAVING_PARTICIPANT_ACCESS: (state, payload) => state.isSavingParticipantAccess = payload,
        SET_IS_PARTICIPANT_ACCESS_SAVED: (state, payload) => state.isParticipantAccessSaved = payload,
        SET_PARTICIPANT_ACCESS_SAVE_ERROR: (state, payload) => state.participantAccessSaveError = payload,
        SET_IS_EVENT_ROLE_ACCESS_DEFAULTS_LOADING: (state, payload) => state.isEventRoleAccessDefaultsLoading = payload,
        SET_IS_EVENT_ROLE_ACCESS_DEFAULTS_LOADED: (state, payload) => state.isEventRoleAccessDefaultsLoaded = payload,
        SET_EVENT_ROLE_ACCESS_DEFAULTS_LOAD_ERROR: (state, payload) => state.eventRoleAccessDefaultsLoadError = payload,
        SET_EVENT_ROLE_ACCESS_DEFAULTS: (state, payload) => state.eventRoleAccessDefaults = payload,
    },
    actions: {
        loadAccess: async ({ getters, commit }) => {
            commit('SET_IS_ACCESS_LOADED', false)
            return axios.get(getters.backendPath() + '/access')
                .then(response => {
                    const data = response.data
                    commit('SET_ACCESS', data)
                    commit('SET_IS_ACCESS_LOADED', true)
                    return data;
                })
        },
        unloadAccess: ({ commit }) => {
            commit('SET_ACCESS', [])
            commit('SET_IS_ACCESS_LOADED', false)
        },
        loadAccessOptions: async ({ getters, commit }, payload) => {
            const eventId = payload && payload.eventId
            let requestUrl = getters.backendPath() + '/access/options'
            if (eventId) {
                requestUrl = getters.backendPath() + '/event/' + eventId + '/access/options'
            }
            commit('SET_IS_ACCESS_OPTIONS_LOADING', true)
            return axios.get(requestUrl)
                .then(response => {
                    const data = response.data
                    commit('SET_IS_ACCESS_OPTIONS_LOADING', false)
                    commit('SET_ACCESS_OPTIONS', data.sort())
                    commit('SET_IS_ACCESS_OPTIONS_LOADED', true)
                    commit('SET_ACCESS_OPTIONS_LOAD_ERROR', null)
                    return data;
                })
                .catch(error => {
                    commit('SET_IS_ACCESS_OPTIONS_LOADING', false)
                    const message = error.message || error.response.data.message
                    commit('SET_ACCESS_OPTIONS_LOAD_ERROR', message)
                    commit('SET_ERROR_INTO_ERROR_LIST', message)
                })
        },
        loadEventAccess: async ({ getters, commit }) => {
            commit('SET_IS_EVENT_ACCESS_LOADING', true)
            return axios.get(getters.backendPath() + '/event/' + getters.event().eventId + '/access')
                .then(response => {
                    const data = response.data
                    commit('SET_IS_EVENT_ACCESS_LOADING', false)
                    commit('SET_EVENT_ACCESS', data)
                    commit('SET_IS_EVENT_ACCESS_LOADED', true)
                    commit('SET_EVENT_ACCESS_LOAD_ERROR', null)
                    return data;
                })
                .catch(error => {
                    commit('SET_IS_EVENT_ACCESS_LOADING', false)
                    commit('SET_EVENT_ACCESS_LOAD_ERROR', error.message || error.response.data.message)
                })
        },
        loadEventRoleAccessDefaults: async ({ getters, commit }, payload) => {
            commit('SET_IS_EVENT_ROLE_ACCESS_DEFAULTS_LOADING', true)
            return axios.get(getters.backendPath() + '/event/' + payload.eventId + '/roleAccessDefaults')
                .then(response => {
                    const data = response.data
                    commit('SET_IS_EVENT_ROLE_ACCESS_DEFAULTS_LOADING', false)
                    commit('SET_EVENT_ROLE_ACCESS_DEFAULTS', data)
                    commit('SET_IS_EVENT_ROLE_ACCESS_DEFAULTS_LOADED', true)
                    commit('SET_EVENT_ROLE_ACCESS_DEFAULTS_LOAD_ERROR', null)
                    return data;
                })
                .catch(error => {
                    commit('SET_IS_EVENT_ROLE_ACCESS_DEFAULTS_LOADING', false)
                    const message = error.message || error.response.data.message
                    commit('SET_EVENT_ROLE_ACCESS_DEFAULTS_LOAD_ERROR', message)
                    commit('SET_ERROR_INTO_ERROR_LIST', message)
                })
        },
        editParticipantAccess: async ({ getters, commit, dispatch }, payload) => {
            if (!getters.isSavingParticipantAccess()) {
                const participants = getters.participants()
                const participantIndex = participants.findIndex(participant => participant.userId === payload.userId)
                if (participantIndex > -1) {
                    const participant = participants[participantIndex]
                    if (!participant.access) {
                        participant.access = []
                    }
                    if (participant.access.includes(payload.accessName)) {
                        participant.access = participant.access.filter(item => item !== payload.accessName)
                    }
                    else {
                        participant.access.push(payload.accessName)
                    }
                    participants[participantIndex] = participant
                }
                commit('SET_PARTICIPANTS', [...participants])
                dispatch('saveParticipantAccess', payload)
            }
        },
        saveParticipantAccess: async ({ getters, commit }, payload) => {
            const eventId = getters.event().eventId
            commit('SET_IS_SAVING_PARTICIPANT_ACCESS', true)
            return axios.post(getters.backendPath() + '/event/' + eventId + '/access/toggle',
                { eventId, participantUserId: payload.userId, accessName: payload.accessName }
            )
                .then(() => {
                    commit('SET_IS_SAVING_PARTICIPANT_ACCESS', false)
                    commit('SET_IS_PARTICIPANT_ACCESS_SAVED', true)
                    commit('SET_PARTICIPANT_ACCESS_SAVE_ERROR', null)
                    commit('SET_IS_SOMETHING_SAVED', true)
                })
                .catch(error => {
                    commit('SET_IS_SAVING_PARTICIPANT_ACCESS', false)
                    const message = error.message || error.response.data.message
                    commit('SET_PARTICIPANT_ACCESS_SAVE_ERROR', message)
                    commit('SET_ERROR_INTO_ERROR_LIST', message)
                })
        }
    }
}
