<template>
    <div v-if="hasValidToken" class="blog-cms-container" :style="{ 'margin-top': 2 * navbarMargin + navbarHeight + 'px' }">
        <div class="content-editor">
            <ContentBrowser class="content-browser"
                            :class="{ active: isViewTitleActive }"
                            :content="content"
                            :selectedIdPath="selectedIdPath"
                            @on-select-item="openContentItem"
                            @on-create-child="createContentItem"
                            @on-delete-item="onDeleteContentItem" />
            <ContentForm class="content-form"
                         :class="{ active: isSubViewTitleActive }"
                         :selectedItem="selectedItem"
                         :selectedIdPath="selectedIdPath"/>
        </div>
        <ConfirmationDialog :isOpen="isDeleteConfirmationDialogVisible"
                            @on-cancel="onCancelItemDelete"
                            @on-confirm="deleteContentItem">
            Do you really want to delete the selected item '{{deleteItem && (deleteItem[deleteItem.nameField] || deleteItem.title || deleteItem.id)}}'?
        </ConfirmationDialog>
    </div>
</template>

<script>
    import ContentBrowser from '@/modules/blog-cms/content-browser.vue'
    import ContentForm from '@/modules/blog-cms/content-form.vue'
    import ConfirmationDialog from '@/components/confirmation-dialog.vue'

    export default {
        name: 'BlogCMS',
        components: {
            ContentBrowser,
            ContentForm,
            ConfirmationDialog
        },
        data: () => {
            return {
                selectedItem: null,
                selectedIdPath: [],
                deleteIdPath: null,
                deleteItem: null,
                isDeleteConfirmationDialogVisible: false
            }
        },
        created() {
            this.$store.dispatch('loadContent').then((content) => {
                this.openContentItem({
                    selectedItem: content,
                    selectedIdPath: ['root']
                });
            })
        },
        computed: {
            hasValidToken() {
                return this.$store.getters.hasValidToken()
            },
            contentEditError() {
                return this.$store.getters.getContentEditError()
            },
            content() {
                return this.$store.getters.getContent()
            },
            navbarHeight() {
                return this.$store.getters.navbarHeight()
            },
            navbarMargin() {
                return this.$store.getters.navbarMargin()
            },
            isViewTitleActive() {
                return this.$store.getters.isViewTitleActive()
            },
            isSubViewTitleActive() {
                return this.$store.getters.isSubViewTitleActive()
            }
        },
        mounted: function () {
            this.$nextTick(function () {
                this.$store.dispatch('setIsViewTitleActive', true)
            })
        },
        methods: {
            openContentItem({ selectedItem, selectedIdPath }) {
                this.selectedItem = selectedItem
                this.selectedIdPath = selectedIdPath
                this.$store.dispatch('setIsSubViewTitleActive', true)
            },
            createContentItem(idPath) {
                this.$store.dispatch('createContentItem', { idPath })
                    .then(newItem => this.openContentItem(newItem))
            },
            onDeleteContentItem(deleteIdPath) {
                this.deleteIdPath = deleteIdPath
                this.deleteItem = this.$store.getters.getContentById(this.deleteIdPath[this.deleteIdPath.length - 1])
                this.isDeleteConfirmationDialogVisible = true
            },
            onCancelItemDelete() {
                this.deleteItem = null
                this.isDeleteConfirmationDialogVisible = false
            },
            deleteContentItem() {
                this.isDeleteConfirmationDialogVisible = false
                this.deleteItem = null
                this.$store.dispatch('deleteContentItem', { idPath: this.deleteIdPath })
                    .then(({ parent, parentIdPath }) => this.openContentItem({
                        selectedItem: parent,
                        selectedIdPath: parentIdPath
                    }))
            }
        }
    }
</script>

<style scoped lang="scss">
    .blog-cms-container {
        height: 100%;
        height: -webkit-fill-available;
        max-height: 100%;
        display: flex;
        flex-direction: column;
    }

    .content-editor {
        position: relative;
        flex-grow: 1;
        box-sizing: border-box;
        margin-left: $view-padding;
        margin-right: $view-padding;
        margin-bottom: $view-padding;
        display: flex;
        overflow: hidden;
    }

    .content-browser {
        width: 400px;
        flex-shrink: 0;
        overflow: auto;
        border: $box-border;
        margin-right: $view-padding;
        padding: 15px;
    }

    .content-form {
        position: relative;
        width: 800px;
        max-width: 800px;
        overflow: auto;
        border: $box-border;
        padding: 15px;
    }

    @media (max-width: 1200px) {
        .content-browser {
            width: -webkit-fill-available;
            margin-right: 0;
            display: none;

            &.active {
                display: block;
            }
        }

        .content-form {
            width: -webkit-fill-available;
            max-width: 100%;
            margin-right: 0;
            display: none;

            &.active {
                display: block;
            }
        }
    }
</style>
