<template>
    <div class="modal"
        v-if="isModalOpen"
    >
        <div class="modal-nav"
            :style="{ ...genericNavItemStyleTop, ...genericNavItemStyleLeft }"
        >
            <Arrow class="nav-item go-back-arrow"
                @click="goBack"
                :size="navbarHeight"
                :angle="-90"
                :animated="true"
            />
            <div class="nav-item title"
                :style="{
                    height: navbarHeight + 'px',
                    'font-size': navbarHeight * 0.85 + 'px'
                }"
            >
                {{ modalTitle }}
            </div>
        </div>
        <ContentBox :scroll="true">
            <slot></slot>
        </ContentBox>
    </div>
</template>

<script>
import Arrow from '@/components/arrow.vue'
import ContentBox from '@/components/content-box.vue'

export default {
    name: 'Modal',
    props: {
    },
    components: {
        Arrow,
        ContentBox
    },
    computed: {
        isModalOpen() {
            return this.$store.getters.isModalOpen()
        },
        modalTitle() {
            return this.$store.getters.modalTitle()
        },
        navbarHeight() {
            return this.$store.getters.navbarHeight()
        },
        navbarMargin() {
            return this.$store.getters.navbarMargin()
        },
        genericNavItemStyleTop() {
            return { 'margin-top': this.navbarMargin + 'px' }
        },
        genericNavItemStyleLeft() {
            return { 'margin-left': this.navbarMargin + 'px' }
        }
    },
    created() {
    },
    methods: {
        goBack() {
            this.$store.dispatch('closeModal')
        }
    }
}
</script>

<style lang="scss" scoped>
    .modal {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $white;
        z-index: $modal-z-index;

        .modal-nav {
            display: flex;

            .nav-item {
                margin-right: $menu-margin;
                font-weight: $boldFont;
            }
        }
    }
</style>
