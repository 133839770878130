<template>
    <div id="start-page">
        <MultiVideo
            :sources="videoSources"
            isCentered
        >
        </MultiVideo>
    </div>
</template>

<script>
import MultiVideo from '@/components/multi-video.vue'

export default {
    name: 'StartPage',
    components: {
        MultiVideo
    },
    mounted() {
        this.$store.dispatch('setIsMenuButtonStandingOut', false)
    },
    unmounted() {
        this.$store.dispatch('setIsMenuButtonStandingOut', true)
    },
    computed: {
        filesUrlPath() {
            return process.env.NODE_ENV === 'development' ? process.env.VUE_APP_FILES_URL_LOCAL + '/' : process.env.VUE_APP_FILES_URL + '/'
        },
        videoSources() {
            const startContent = this.$store.getters.getContentById('start')
            return startContent?.children?.filter(item => item.isActive).map(item => this.filesUrlPath + item.source) || []
        }
    }
}
</script>

<style scoped lang="scss">
    #start-page {
        position: fixed;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
        background-color: $black;
    }
</style>
