import axios from 'axios'
import { findItem, getFreeItemId } from '../utils/content.utils.js'

let contentEditTimeout = null
const CONTENT_EDIT_TIMEOUT_DELAY = 2000

export const contentStore = {
    state: {
        content: {},
        contentSavingState: null,
        contentEditError: null
    },
    getters: {
        getContent: state => () => state.content,
        getContentSavingState: state => () => state.contentSavingState,
        getContentEditError: state => () => state.contentEditError,
        getContentById: state => (itemId) => state.content && state.content.children && findItem(itemId, state.content.children)
    },
    mutations: {
        SET_CONTENT: (state, payload) => state.content = payload,
        APPLY_CONTENT_TYPE: (state, payload) => {
            const item = findItem(payload.itemId, state.content.children)
            const parent = findItem(payload.parentId, state.content.children)
            const template = parent.childTemplates.find((childTemplate) => childTemplate.contentType === payload.contentType)
            for (let key in template) {
                if (Object.prototype.hasOwnProperty.call(template, key)) {
                    item[key] = template[key]
                }
            }
            delete item.availableContentTypes
        },
        SET_CONTENT_FIELD: (state, payload) => {
            const item = findItem(payload.itemId, [state.content])
            item[payload.field] = payload.value
        },
        SET_CONTENT_SAVING_STATE: (state, payload) => state.contentSavingState = payload,
        SET_CONTENT_EDIT_ERROR: (state, payload) => state.contentEditError = payload,
        UNSET_CONTENT_ITEM: (state, payload) => {
            const parent = findItem(payload.parentId, state.content.children)
            parent.children.splice(payload.deleteIndex, 1)
        },
        MOVE_CONTENT_ITEM: (state, payload) => {
            const parent = findItem(payload.parentItem.id, state.content.children)
            const oldIndex = parent.children.findIndex((item) => item.id === payload.itemToMove.id)
            parent.children.splice(oldIndex, 1)
            const newIndex = parent.children.findIndex((item) => item.id === payload.itemBeforeNewIndex.id) + 1
            parent.children.splice(newIndex, 0, payload.itemToMove)
        }
    },
    actions: {
        loadContent: ({ commit, getters }, payload) => {
            return axios.get(getters.backendPath() + '/content', payload)
                .then(response => {
                    commit('SET_CONTENT', response.data)
                    return response.data;
                })
        },
        createContentItem: ({ state, commit, dispatch }, { idPath }) => {
            const parentId = idPath[idPath.length - 1]
            const parentItem = findItem(parentId, state.content.children)
            let newItem
            if (parentItem.childTemplate) {
                newItem = {
                    ...parentItem.childTemplate,
                    id: getFreeItemId(state.content.children)
                }
            } else if (parentItem.childTemplates && parentItem.childTemplates.length) {
                newItem = {
                    id: getFreeItemId(state.content.children),
                    title: 'no-type',
                    availableContentTypes: parentItem.childTemplates.map((childTemplate) => ({
                        contentType: childTemplate.contentType,
                        title: childTemplate.title
                    }))
                }
            } else {
                newItem = {
                    id: getFreeItemId(state.content.children),
                    title: 'undefined'
                }
            }
            if (parentItem.children) {
                parentItem.children.push(newItem)
            } else {
                commit('SET_CONTENT_FIELD', {
                    itemId: parentItem.id,
                    field: 'children',
                    value: [newItem]
                })
            }
            const newItemIdPath = idPath.slice()
            newItemIdPath.push(newItem.id)
            dispatch('saveContent')
            return {
                selectedItem: newItem,
                selectedIdPath: newItemIdPath
            }
        },
        saveContent: ({ state, getters, commit }) => {
            commit('SET_CONTENT_SAVING_STATE', 'SAVING')
            return axios.post(getters.backendPath() + '/content', {
                content: state.content
            }).then(() => {
                commit('SET_CONTENT_SAVING_STATE', 'SAVED')
                commit('SET_CONTENT_EDIT_ERROR', null)
            }, response => {
                if (response.data.status === 401) {
                    commit('SET_CONTENT_EDIT_ERROR', 'no valid login')
                } else {
                    commit('SET_CONTENT_EDIT_ERROR', response.data.message)
                }
            })
        },
        applyContentType: ({ commit, dispatch }, payload) => {
            const itemId = payload.idPath[payload.idPath.length - 1]
            const parentId = payload.idPath[payload.idPath.length - 2]
            commit('APPLY_CONTENT_TYPE', { itemId, parentId, contentType: payload.contentType })
            dispatch('saveContent')
        },
        editContent: ({ commit, dispatch }, payload) => {
            commit('SET_CONTENT_FIELD', payload)
            commit('SET_CONTENT_SAVING_STATE', '')
            clearTimeout(contentEditTimeout)
            contentEditTimeout = setTimeout(() => {
                dispatch('saveContent')
            }, CONTENT_EDIT_TIMEOUT_DELAY)
        },
        uploadFile: ({ getters, commit, dispatch }, payload) => {
            const formData = new FormData()
            formData.append('file', payload.file, payload.file.name)
            return axios.post(getters.backendPath() + '/uploadFile', formData)
                .then(() => {
                    commit('SET_CONTENT_FIELD', payload)
                    dispatch('saveContent')
                }, () => {
                    commit('SET_CONTENT_EDIT_ERROR', 'could not upload file')
                })
        },
        deleteContentItem: ({ commit, state, dispatch }, { idPath }) => {
            const idToDelete = idPath[idPath.length - 1]
            const parentId = idPath[idPath.length - 2]
            const parentItem = findItem(parentId, state.content.children)
            const deleteIndex = parentItem.children.findIndex((item) => item.id === idToDelete)
            commit('UNSET_CONTENT_ITEM', {
                parentId,
                deleteIndex
            })
            idPath.pop()
            return dispatch('saveContent')
                .then(() => ({
                    parent: findItem(parentId, state.content.children),
                    parentIdPath: idPath
                }))
        },
        moveContentItem: ({ commit, dispatch }, { parentItem, itemToMove, itemBeforeNewIndex }) => {
            commit('MOVE_CONTENT_ITEM', {
                parentItem,
                itemToMove,
                itemBeforeNewIndex
            })
            return dispatch('saveContent')
        }
    }
}
