import axios from 'axios'

export const actionsStore = {
    state: {
        actions: {}
    },
    getters: {
        actions: state => () => Object.values(state.actions),
        action: state => actionId => state.actions[actionId],
        actionTitle: state => actionId => state.actions[actionId] && state.actions[actionId].title,
        isActionActive: state => actionId => state.actions[actionId] && state.actions[actionId].isActive,
        actionError: state => actionId => state.actions[actionId] && state.actions[actionId].error,
        actionResponseType: state => actionId => state.actions[actionId] && state.actions[actionId].responseType,
        actionResponse: state => actionId => state.actions[actionId] && state.actions[actionId].response,
    },
    mutations: {
        INIT_ACTION: (state, payload) => {
            state.actions[payload.actionId] = {
                ...payload,
                isActive: false,
                error: null,
                response: null
            }
        },
        CLEAR_ACTIONS: state => {
            state.actions = {}
        },
        SET_ACTION: (state, payload) => state.actions[payload.actionId] = payload,
        SET_IS_ACTION_ACTIVE: (state, payload) => state.actions[payload.actionId].isActionActive = payload.isActionActive,
        SET_ACTION_ERROR: (state, payload) => state.actions[payload.actionId].error = payload.error,
        SET_ACTION_RESPONSE: (state, payload) => state.actions[payload.actionId].response = payload.response,
    },
    actions: {
        initActions: async ({ getters, commit }) => {
            const event = getters.event()
            if (event.actions && event.actions.length) {
                event.actions.forEach(action => {
                    commit('INIT_ACTION', action)
                })
            }
        },
        clearActions: async ({ commit }) => {
            commit('CLEAR_ACTIONS')
        },
        doAction: async ({ getters, commit }, payload) => {
            const actionId = payload.actionId
            const action = getters.action(actionId)
            let queryString = ''
            if (action.queries && action.queries.length) {
                queryString = '?' + action.queries.map(({ name, value }) => name + '=' + value).join('&')
            }
            commit('SET_IS_ACTION_ACTIVE', { actionId, isActionActive: true })
            return axios.get(getters.backendPath() + '/event/' + getters.event().eventId + '/actions/' + action.resource + queryString)
                .then(response => {
                    const data = response.data
                    commit('SET_ACTION_RESPONSE', { actionId, response: data })
                    commit('SET_IS_ACTION_ACTIVE',  { actionId, isActionActive: false })
                    commit('SET_IS_EVENTS_LOADED', true)
                    if (action.responseHandling === 'downloadJson') {
                        downloadObjectAsJson(data, action.fileName)
                    }
                    return data
                })
                .catch(error => {
                    commit('SET_IS_ACTION_ACTIVE', { actionId, isActionActive: false })
                    const message = error.message || error.response.data.message
                    commit('SET_ACTION_ERROR', { actionId, error: message })
                    commit('SET_ERROR_INTO_ERROR_LIST', message)
                })
        }
    }
}

function downloadObjectAsJson(exportObj, exportName) {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj))
    var downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute("href",     dataStr)
    downloadAnchorNode.setAttribute("download", exportName + ".json")
    document.body.appendChild(downloadAnchorNode) // required for firefox
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
  }