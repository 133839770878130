import router from '@/router'
import axios from 'axios'

let surveyUserDataEditTimeout = null
const SURVEY_EDIT_TIMEOUT_DELAY = 2000

export const surveyStore = {
    state: {
        isSurveyLoading: false,
        isSurveyLoaded: false,
        surveyLoadError: null,
        survey: {},
        surveyUserDataUserId: null,
        isSurveyUserDataLoading: false,
        isSurveyUserDataLoaded: false,
        surveyUserDataLoadError: null,
        surveyUserData: {},
        surveyUserDataSavingState: '',
        surveyUserDataSaveError: null,
        isSavingSurveyState: false,
        isSurveyStateSaved: false,
        surveyStateSaveError: null
    },
    getters: {
        isSurveyLoading: state => () => state.isSurveyLoading,
        isSurveyLoaded: state => () => state.isSurveyLoaded,
        surveyLoadError: state => () => state.surveyLoadError,
        survey: state => () => state.survey,
        surveyUserDataUserId: state => () => state.surveyUserDataUserId,
        isSurveyUserDataLoading: state => () => state.isSurveyUserDataLoading,
        isSurveyUserDataLoaded: state => () => state.isSurveyUserDataLoaded,
        surveyUserDataLoadError: state => () => state.surveyUserDataLoadError,
        surveyUserData: state => () => state.surveyUserData,
        surveyUserDataSavingState: state => () => state.surveyUserDataSavingState,
        surveyUserDataSaveError: state => () => state.surveyUserDataSaveError,
        isSavingSurveyState: state => () => state.isSavingSurveyState,
        isSurveyStateSaved: state => () => state.isSurveyStateSaved,
        surveyStateSaveError: state => () => state.surveyStateSaveError,
    },
    mutations: {
        SET_IS_SURVEY_LOADING: (state, payload) => state.isSurveyLoading = payload,
        SET_IS_SURVEY_LOADED: (state, payload) => state.isSurveyLoaded = payload,
        SET_SURVEY_LOAD_ERROR: (state, payload) => state.surveyLoadError = payload,
        SET_SURVEY: (state, payload) => state.survey = payload,
        SET_SURVEY_USER_DATA_USER_ID: (state, payload) => state.surveyUserDataUserId = payload,
        SET_IS_SURVEY_USER_DATA_LOADING: (state, payload) => state.isSurveyUserDataLoading = payload,
        SET_IS_SURVEY_USER_DATA_LOADED: (state, payload) => state.isSurveyUserDataLoaded = payload,
        SET_IS_SURVEY_USER_DATA_LOAD_ERROR: (state, payload) => state.surveyUserDataLoadError = payload,
        SET_SURVEY_USER_DATA: (state, payload) => state.surveyUserData = payload,
        SET_SURVEY_USER_DATA_SAVING_STATE: (state, payload) => state.surveyUserDataSavingState = payload,
        SET_SURVEY_USER_DATA_SAVE_ERROR: (state, payload) => state.surveyUserDataSaveError = payload,
        SET_IS_SAVING_SURVEY_STATE: (state, payload) => state.isSavingSurveyState = payload,
        SET_IS_SURVEY_STATE_SAVED: (state, payload) => state.isSurveyStateSaved = payload,
        SET_SURVEY_STATE_SAVE_ERROR: (state, payload) => state.surveyStateSaveError = payload
    },
    actions: {
        loadSurvey: async ({ getters, commit }, payload) => {
            commit('SET_IS_SURVEY_LOADING', true)
            commit('SET_SURVEY_LOAD_ERROR', null)
            return axios.get(getters.backendPath() + '/event/' + payload.eventId + '/survey/' + payload.surveyId)
                .then(response => {
                    const data = response.data
                    commit('SET_SURVEY', data)
                    commit('SET_IS_SURVEY_LOADING', false)
                    commit('SET_IS_SURVEY_LOADED', true)
                    return data
                })
                .catch(error => {
                    commit('SET_IS_SURVEY_LOADING', false)
                    commit('SET_SURVEY_LOAD_ERROR', error.message || error.response.data.message)
                })
        },
        unloadSurvey: async ({ commit }) => {
            commit('SET_SURVEY', {})
        },
        saveSurveyState: async ({ getters, commit, dispatch }, payload) => {
            const eventId = getters.event().eventId
            const surveyId = payload.surveyId
            if (!getters.isSavingSurveyState()) {
                commit('SET_IS_SAVING_SURVEY_STATE', true)
                return axios.post(getters.backendPath() + '/event/' + eventId + '/survey/' + surveyId + '/state', {
                    surveyState: payload.state
                })
                    .then(response => {
                        const data = response.data
                        dispatch('loadEvent', { eventId })
                        commit('SET_IS_SAVING_SURVEY_STATE',  false)
                        commit('SET_IS_SURVEY_STATE_SAVED', true)
                        commit('SET_IS_SOMETHING_SAVED', true)
                        return data
                    })
                    .catch(error => {
                        commit('SET_IS_SAVING_SURVEY_STATE', false)
                        const message = error.message || error.response.data.message
                        commit('SET_SURVEY_STATE_SAVE_ERROR', message)
                        commit('SET_ERROR_INTO_ERROR_LIST', message)
                    })
            }
        },
        loadSurveyUserData: async ({ getters, commit }, payload) => {
            commit('SET_IS_SURVEY_USER_DATA_LOADING', true)
            commit('SET_IS_SURVEY_USER_DATA_LOAD_ERROR', null)
            commit('SET_SURVEY_USER_DATA_SAVING_STATE', '')
            const userId = getters.surveyUserDataUserId() ? '/' + getters.surveyUserDataUserId() : ''
            return axios.get(getters.backendPath() + '/event/' + payload.eventId + '/survey/' + payload.surveyId + '/userData' + userId)
                .then(response => {
                    const data = response.data
                    if (data.status >= 400) {
                        commit('SET_SURVEY_USER_DATA', {})
                        commit('SET_IS_SURVEY_USER_DATA_LOAD_ERROR', data)
                    } else if (data.type === 'ERROR') {
                        commit('SET_SURVEY_USER_DATA', {})
                        commit('SET_IS_SURVEY_USER_DATA_LOAD_ERROR', data.message)
                    }
                    else {
                        commit('SET_SURVEY_USER_DATA', data)
                        commit('SET_IS_SURVEY_USER_DATA_LOADED', true)
                    }
                    commit('SET_IS_SURVEY_USER_DATA_LOADING', false)
                    return data
                })
                .catch(error => {
                    commit('SET_SURVEY_USER_DATA', {})
                    commit('SET_IS_SURVEY_USER_DATA_LOADING', false)
                    commit('SET_IS_SURVEY_USER_DATA_LOAD_ERROR', error.response.data.message)
                })
        },
        saveSurveyUserData: async ({ getters, commit }, payload) => {
            commit('SET_SURVEY_USER_DATA_SAVING_STATE', 'SAVING')
            return axios.post(
                getters.backendPath() + '/event/' + payload.eventId + '/survey/' + payload.surveyId + '/userData',
                { surveyUserData: getters.surveyUserData() }
            )
                .then(() => {
                    commit('SET_SURVEY_USER_DATA_SAVING_STATE', 'AUTOSAVED')
                    commit('SET_SURVEY_USER_DATA_SAVE_ERROR', null)
                    commit('SET_IS_SOMETHING_SAVED', true)
                }, ({ response }) => {
                    let message = response.data && response.data.message
                    if (response.data && response.data.status === 401) {
                        message = 'no valid login'
                    }
                    commit('SET_SURVEY_USER_DATA_SAVE_ERROR', message)
                    commit('SET_ERROR_INTO_ERROR_LIST', message)
                })
        },
        editSurveyUserData: async ({ commit, dispatch }, payload) => {
            commit('SET_SURVEY_USER_DATA_SAVING_STATE', '')
            commit('SET_SURVEY_USER_DATA', payload.surveyUserData)
            clearTimeout(surveyUserDataEditTimeout)
            surveyUserDataEditTimeout = setTimeout(() => {
                dispatch('saveSurveyUserData', payload)
            }, SURVEY_EDIT_TIMEOUT_DELAY)
        },
        openParticipantSurvey: async ({ getters, commit }, payload) => {
            if (getters.hasAccess('openParticipantSurvey')) {
                commit('SET_RETURN_ROUTE', { ...payload.returnRoute })
				commit('SET_SURVEY_USER_DATA_USER_ID', payload.userId)
                router.push(payload.routeAfter)
			}
        }
    }
}
