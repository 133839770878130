<template>
    <div class="event-action">
        <button class="action-button"
            :class="{ active: action.isActive }"
            @click="doAction"
        >
            {{ action.title }}
        </button>
    </div>
</template>

<script>

export default {
    name: 'EventAction',
    props: {
        action: Object
    },
    components: {
    },
    computed: {
        isActionActive() {
            return this.$store.getters.isActionActive(this.action.actionId)
        }
    },
    created() {
    },
    methods: {
        doAction() {
            this.$store.dispatch('doAction', { actionId: this.action.actionId })
        }
    }
}
</script>

<style lang="scss" scoped>
    .event-action {
        width: 100%;
        margin-bottom: $view-padding;

        .action-button {
            width: 100%;
            text-align: left;

            &.active {
                border-color: $primary;
                background-color: $primary;
                color: $white;
                cursor: default;
            }
        }
    }
</style>
