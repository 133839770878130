<template>
    <div class="view-title">
        <div class="text"
            :class="{ active: isViewTitleActive }"
            :style="{
                height: navbarHeight + 'px',
                'font-size': navbarHeight * 0.85 + 'px'
            }"
            @click="onViewTitleClick" 
        >
            {{ viewTitle }}
        </div>
        <div v-if="subViewTitle && subViewTitle !== ''"
            class="view-title-separator"
            :style="{
                height: navbarHeight + 'px',
                'font-size': navbarHeight * 0.85 + 'px'
            }"
        >
            /
        </div>
        <div class="text"
            :class="{ active: isSubViewTitleActive }"
            :style="{
                height: navbarHeight + 'px',
                'font-size': navbarHeight * 0.85 + 'px'
            }"
            @click="onSubViewTitleClick" 
        >
            {{ subViewTitle }}
        </div>
    </div>
</template>

<script>

export default {
    name: 'ViewTitle',
    components: {
    },
    props: {
        title: String
    },
    data: function () {
        return {
        }
    },
    computed: {
        navbarHeight() {
            return this.$store.getters.navbarHeight()
        },
        navbarMargin() {
            return this.$store.getters.navbarMargin()
        },
        viewTitle() {
            return this.$store.getters.viewTitle()
        },
        subViewTitle() {
            return this.$store.getters.subViewTitle()
        },
        isViewTitleActive() {
            return this.$store.getters.isViewTitleActive()
        },
        isSubViewTitleActive() {
            return this.$store.getters.isSubViewTitleActive()
        }
    },
    methods: {
        onViewTitleClick: function() {
            this.$store.dispatch('setIsViewTitleActive', true)
        },
        onSubViewTitleClick: function() {
            this.$store.dispatch('setIsSubViewTitleActive', true)
        }
    }
}
</script>

<style scoped lang="scss">
    .view-title {
        position: relative;
        display: flex;
        white-space: nowrap;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
    
        .text,
        .view-title-separator {
            font-size: $page-title-font-size;
            font-weight: $boldFont;
        }

        .text.active {
            color: $secondary;
        }
    }

    @media (max-width: 900px) {
        .view-title {
            .text,
            .view-title-separator {
                font-size: 8vw;
            }
        }
    }
</style>