<template>
    <div id="event-settings">
        <div class="section-container">
            <EventSurveyAction v-for="action in surveyActions" :key="action.title" :action="action" />
        </div>
        <div class="section-container">
            <EventExpensesAction v-for="action in expensesActions" :key="action.title" :action="action" />
        </div>
        <div class="section-container">
            <EventAction v-for="action in actions" :key="action.title" :action="action" />
        </div>
    </div>
</template>

<script>
import EventAction from '@/modules/events/event-action.vue'
import EventSurveyAction from '@/modules/events/event-survey-action.vue'
import EventExpensesAction from '@/modules/events/event-expenses-action.vue'

export default {
    name: 'EventSettings',
    props: {
    },
    components: {
        EventAction,
        EventSurveyAction,
        EventExpensesAction
    },
    computed: {
        route() {
            return this.$store.getters.route()
        },
        event() {
            return this.$store.getters.event()
        },
        actions() {
            return this.$store.getters.actions()
        },
        surveyActions() {
            const event = this.$store.getters.event()
            const surveyActions = []
            if (event && event.surveys) {
                event.surveys.forEach(surveyBaseData => {
                    surveyActions.push({
                        ...surveyBaseData
                    })
                })
            }
            return surveyActions
        },
        expensesActions() {
            const event = this.$store.getters.event()
            const expensesActions = []
            if (event && event.expenses) {
                event.expenses.forEach(expensesBaseData => expensesActions.push({ ...expensesBaseData }))
            }
            return expensesActions
        }
    },
    watch: {
        event(event) {
            if (event && this.$store.getters.isEventAccessLoaded() && !this.$store.getters.hasAccess('settings')) {
                this.$router.push({ name: 'event', params: { eventId: event.eventId } })
            }
        }
    },
    created() {
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
    #event-settings {
        width: 100%;

        .section-title {
            margin-bottom: $view-padding;
        }

        .section-container {
            margin-bottom: $view-padding;
            font-size: $font-size-s;
        }
    }
</style>
