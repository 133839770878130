import axios from 'axios'

let participantsEditTimeout = null
const PARTICIPANTS_EDIT_TIMEOUT_DELAY = 2000

export const participantsStore = {
    state: {
        isParticipantsLoading: false,
        isParticipantsLoaded: false,
        participantsLoadError: null,
        participants: [],
        participantsSavingState: '',
        participantsSaveError: null,
        isParticipantsRolesLoading: false,
        isParticipantsRolesLoaded: false,
        participantsRolesLoadError: null,
        participantsRoles: [],
    },
    getters: {
        isParticipantsLoading: state => () => state.isParticipantsLoading,
        isParticipantsLoaded: state => () => state.isParticipantsLoaded,
        participantsLoadError: state => () => state.participantsLoadError,
        participants: state => () => state.participants,
        participantsSavingState: state => () => state.participantsSavingState,
        participantsSaveError: state => () => state.participantsSaveError,
        isParticipantsRolesLoading: state => () => state.isParticipantsRolesLoading,
        isParticipantsRolesLoaded: state => () => state.isParticipantsRolesLoaded,
        participantsRolesLoadError: state => () => state.participantsRolesLoadError,
        participantsRoles: state => () => state.participantsRoles,
    },
    mutations: {
        SET_IS_PARTICIPANTS_LOADING: (state, payload) => state.isParticipantsLoading = payload,
        SET_IS_PARTICIPANTS_LOADED: (state, payload) => state.isParticipantsLoaded = payload,
        SET_PARTICIPANTS_LOAD_ERROR: (state, payload) => state.participantsLoadError = payload,
        SET_PARTICIPANTS: (state, payload) => state.participants = payload,
        SET_PARTICIPANTS_SAVING_STATE: (state, payload) => state.participantsSavingState = payload,
        SET_PARTICIPANTS_SAVE_ERROR: (state, payload) => state.participantsSaveError = payload,
        SET_IS_PARTICIPANTS_ROLES_LOADING: (state, payload) => state.isParticipantsRolesLoading = payload,
        SET_IS_PARTICIPANTS_ROLES_LOADED: (state, payload) => state.isParticipantsRolesLoaded = payload,
        SET_PARTICIPANTS_ROLES_LOAD_ERROR: (state, payload) => state.participantsRolesLoadError = payload,
        SET_PARTICIPANTS_ROLES: (state, payload) => state.participantsRoles = payload,
    },
    actions: {
        loadParticipants: async ({ commit, getters }, payload) => {
            commit('SET_IS_PARTICIPANTS_LOADING', true)
            commit('SET_PARTICIPANTS_LOAD_ERROR', null)
            return axios.get(getters.backendPath() + '/event/' + payload.eventId + '/participants')
                .then(response => {
                    const data = response.data
                    if (data.type && data.type === 'ERROR') {
                        commit('SET_PARTICIPANTS', [])
                        commit('SET_PARTICIPANTS_LOAD_ERROR', data.error)
                    }
                    else {
                        commit('SET_PARTICIPANTS', data)
                        commit('SET_IS_PARTICIPANTS_LOADED', true)
                    }
                    commit('SET_IS_PARTICIPANTS_LOADING', false)
                    return data
                })
                .catch(error => {
                    commit('SET_IS_PARTICIPANTS_LOADING', false)
                    commit('SET_PARTICIPANTS_LOAD_ERROR', error)
                })
        },
        saveParticipants: async ({ getters, commit }, payload) => {
            commit('SET_PARTICIPANTS_SAVING_STATE', 'SAVING')
            return axios.post(
                getters.backendPath() + '/event/' + payload.eventId + '/participants',
                { participants: payload.participants }
            )
                .then(response => {
                    const data = response.data
                    if (data.type && data.type === 'ERROR') {
                        commit('SET_PARTICIPANTS_SAVE_ERROR', data.error)
                        commit('SET_PARTICIPANTS_SAVING_STATE', '')
                    }
                    else {
                        commit('SET_PARTICIPANTS_SAVING_STATE', 'AUTOSAVED')
                        commit('SET_PARTICIPANTS_SAVE_ERROR', null)
                        commit('SET_IS_SOMETHING_SAVED', true)
                    }
                }, response => {
                    if (response.data && response.data.status === 401) {
                        const message = 'no valid login'
                        commit('SET_PARTICIPANTS_SAVE_ERROR', message)
                        commit('SET_ERROR_INTO_ERROR_LIST', message)
                    }
                    else {
                        const message = response.data.message
                        commit('SET_PARTICIPANTS_SAVE_ERROR', message)
                        commit('SET_ERROR_INTO_ERROR_LIST', message)
                    }
                })
        },
        editParticipants: async ({ commit, dispatch }, payload) => {
            commit('SET_PARTICIPANTS_SAVING_STATE', '')
            commit('SET_PARTICIPANTS', payload.participants)
            clearTimeout(participantsEditTimeout)
            participantsEditTimeout = setTimeout(() => {
                dispatch('saveParticipants', payload)
            }, PARTICIPANTS_EDIT_TIMEOUT_DELAY)
        },
        unloadParticipants: ({ commit }) => {
            commit('SET_PARTICIPANTS', [])
            commit('SET_IS_PARTICIPANTS_LOADED', false)
        },
        loadParticipantsRoles: async ({ commit, getters }, payload) => {
            commit('SET_IS_PARTICIPANTS_ROLES_LOADING', true)
            commit('SET_PARTICIPANTS_ROLES_LOAD_ERROR', null)
            return axios.get(getters.backendPath() + '/event/' + payload.eventId + '/participantsRoles')
                .then(response => {
                    const data = response.data
                    if (data.type && data.type === 'ERROR') {
                        commit('SET_PARTICIPANTS_ROLES', [])
                        commit('SET_PARTICIPANTS_ROLES_LOAD_ERROR', data.error)
                    }
                    else {
                        commit('SET_PARTICIPANTS_ROLES', data)
                        commit('SET_IS_PARTICIPANTS_ROLES_LOADED', true)
                    }
                    commit('SET_IS_PARTICIPANTS_ROLES_LOADING', false)
                    return data
                })
                .catch(error => {
                    commit('SET_IS_PARTICIPANTS_ROLES_LOADING', false)
                    commit('SET_PARTICIPANTS_ROLES_LOAD_ERROR', error.response.data.message)
                })
        },
        registerParticipant: async (_, payload) => {
            console.log(payload)
            console.log('HEX')
        }
    }
}
