<template>
  <div class="intern-page">
    <Announcement
        v-for="announcement in announcements"
        :key="announcement.title"
        :announcement="announcement"
    />
  </div>
</template>

<script>
import Announcement from '@/components/announcement.vue'

export default {
  name: 'InternPage',
  components: {
    Announcement
  },
  mounted() {
    this.$nextTick(function () {
      this.load()
    })
  },
  computed: {
    announcements() {
      const announcementsData = this.$store.getters.getInternBaseDataById('announcements')
      const announcements = announcementsData && announcementsData.children || []
      if (announcements && announcements.length) {
        announcements.forEach((announcement, index) => {
          if (announcement.children) {
            announcements[index] = {
              ...announcement,
              links: announcement.children
            }
          }
        })
      }
      return announcements 
    }
  },
  methods: {
    load() {
      this.$store.dispatch('loadInternBaseData')
    }
  }
}
</script>

<style scoped lang="scss">
    .intern-page {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
</style>