<template>
    <nav>
        <div class="generic-nav-item-container">
            <div class="generic-nav-items left">
                <div class="generic-nav-item" :style="{ ...genericNavItemStyleTop, ...genericNavItemStyleLeft }">
                    <Arrow v-if="isPageTitleAnimationDone && (isUserOpened || isParticipantSurveyOpened)"
                        class="go-back-arrow"
                        @click="navigateBack"
                        :size="navbarHeight"
                        :angle="-90"
                        :animated="isPageTitleAnimationDone"
                    />
                    <slot name="generic-nav-item-left-start"></slot>
                </div>
                <div class="generic-nav-item">
                    <slot name="generic-nav-item-left-center"></slot>
                </div>
                <div class="generic-nav-item">
                    <slot name="generic-nav-item-left-end"></slot>
                </div>
            </div>
            <div class="generic-nav-items center">
                <div class="generic-nav-item">
                    <slot name="generic-nav-item-center-start"></slot>
                </div>
                <div class="generic-nav-item">
                    <slot name="generic-nav-item-center-center"></slot>
                </div>
                <div class="generic-nav-item">
                    <slot name="generic-nav-item-center-end"></slot>
                </div>
            </div>
            <div class="generic-nav-items right">
                <div class="generic-nav-item">
                    <slot name="generic-nav-item-right-start"></slot>
                </div>
                <div class="generic-nav-item">
                    <slot name="generic-nav-item-right-center"></slot>
                </div>
                <div class="generic-nav-item">
                    <slot name="generic-nav-item-right-end"></slot>
                </div>
            </div>
        </div>
        <div class="click-catcher" @click="closeMenu" v-bind:class="{ active: menuIsOpen }"></div>
        <div class="menu" v-bind:class="{ open: menuIsOpen }">
            <div class="home-link"
                v-if="homeLinkRouteName"
            >
                <router-link @click="closeMenu" :to="{ name: homeLinkRouteName }">
                    {{ homeLinkName || homeLinkRouteName }}
                </router-link>
            </div>
            <div class="menu-links">
                <div class="menu-link"
                    v-for="{ routeName, title, params } in menuLinks"
                    :key="routeName"
                    :class="{ 'is-active': isMatchingRoute(routeName, params) }"
                >
                    <router-link @click="closeMenu" :to="{ name: routeName }">{{ title }}</router-link>
                </div>
                <div v-if="subMenuLinks && subMenuLinks.length">
                    <div class="sub-menu-separator"></div>
                    <div class="menu-link"
                        v-for="{ routeName, title, params } in subMenuLinks"
                        :key="routeName"
                        :class="{ 'is-active': isMatchingRoute(routeName, params) }"
                    >
                        <router-link @click="closeMenu" :to="{ name: routeName, params }">{{ title }}</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="nav-bar">
            <div class="nav-bar-item">
                <slot name="nav-bar-item-right-end"></slot>
            </div>
            <RequestOutcomeIndicator
                v-if="isPageTitleAnimationDone"
                :size="navbarHeight"
                :margin="navbarMargin"
            />
            <MenuButton class="nav-bar-item"
                @click="toggleMenu"
                :size="navbarHeight"
                :margin="navbarMargin"
                :menuIsOpen="menuIsOpen"
                :animated="isPageTitleAnimationDone"
            />
        </div>
    </nav>
</template>

<script>
import Arrow from '@/components/arrow.vue'
import MenuButton from '@/modules/navigation/menu-button.vue'
import RequestOutcomeIndicator from '@/modules/navigation/request-outcome-indicator.vue'

export default {
    name: 'Navigation',
    components: {
        Arrow,
        MenuButton,
        RequestOutcomeIndicator
    },
    props: {
        homeLinkName: String,
        homeLinkRouteName: String,
        menuLinks: Array,
        subMenuLinks: Array
    },
    data: function () {
        return {
            isMobileView: false,
            menuIsOpen: false,
            showWorkInfoIsActive: false
        }
    },
    computed: {
        navbarHeight() {
            return this.$store.getters.navbarHeight()
        },
        navbarMargin() {
            return this.$store.getters.navbarMargin()
        },
        genericNavItemStyleTop() {
            return { 'margin-top': this.navbarMargin + 'px' }
        },
        genericNavItemStyleLeft() {
            return { 'margin-left': this.navbarMargin + 'px' }
        },
        isPageTitleAnimationDone() {
            return this.$store.getters.isPageTitleAnimationDone()
        },
        hasValidToken() {
            return this.$store.getters.hasValidToken()
        },
        activeRoute() {
            return this.$store.getters.route()
        },
        isUserOpened() {
            return this.$store.getters.isUserOpened()
        },
        isParticipantSurveyOpened() {
            return this.$store.getters.surveyUserDataUserId()
        }
    },
    methods: {
        openMenu() {
            this.$store.dispatch('setMenuOpenState', true)
            this.menuIsOpen = true
        },
        closeMenu() {
            this.$store.dispatch('setMenuOpenState', false)
            this.menuIsOpen = false
        },
        toggleMenu() {
            this.$store.dispatch('setMenuOpenState', !this.menuIsOpen)
            this.menuIsOpen = !this.menuIsOpen
        },
        isMatchingRoute(routeName, params) {
            if (this.activeRoute.name === routeName) {
                if (params) {
                    let isParamsMatch = true
                    for (const [paramKey, paramValue] of Object.entries(params)) {
                        const controlParamValue = this.activeRoute.params[paramKey]
                        if (Array.isArray(paramValue) && Array.isArray(controlParamValue)) {
                            paramValue.forEach(item => {
                                if (!controlParamValue.includes(item)) {
                                    isParamsMatch = false
                                }
                            })
                        }
                        else if (controlParamValue && paramValue != controlParamValue) {
                            isParamsMatch = false
                        }
                    }
                    return isParamsMatch
                }
                else {
                    return true
                }
            }
            else {
                return false
            }
        },
        navigateBack() {
            if (this.$store.getters.isUserOpened()) {
                this.$store.dispatch('logback')
            }
            else {
                this.$store.dispatch('navigateBack')
            }
        }
    }
}
</script>

<style scoped lang="scss">
    nav {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        pointer-events: none;
        z-index: 10;
    }

    .generic-nav-item-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        pointer-events: none;

        .generic-nav-items {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 33%;

            &.left {
                align-items: flex-start;
            }

            &.center {
                align-items: center;
            }

            &.right {
                align-items: flex-end;
            }

            .generic-nav-item {
                display: flex;
                margin: $menu-margin;
                pointer-events: all;
            }
        }
    }

    .click-catcher {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        pointer-events: none;
    }

    .click-catcher.active {
        pointer-events: all;
    }

    .nav-bar {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        pointer-events: none;
    }

    .nav-bar-item {
        margin-top: $menu-margin;
        margin-right: 20px;
        pointer-events: all;

        &.hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    .go-back-arrow {
        margin-right: $view-padding;
    }

    .menu {
        position: fixed;
        top: 0px;
        right: 0px;
        width: 400px;
        margin: $menu-margin;
        padding: $menu-padding;
        padding-top: calc(2 * #{$menu-padding});
        background-color: transparent;
        color: transparent;
        pointer-events: none;
        transition: background-color 200ms, color 200ms;

        a,
        .control {
            color: transparent;
            text-decoration: none;
            font-size: 1.5rem;
            line-height: 2.4rem;
        }

        .home-link {
            margin-top: -20px;
        }

        .menu-links {
            padding-top: calc(4 * #{$view-padding});
        }

        .sub-menu-separator {
            width: 100%;
            height: 2px;
            margin-top: 10px;
            margin-bottom: 10px;
            background-color: transparent;
        }
    }

    @media (max-width: 900px) {
        .menu {
            width: auto;
            left: 0px;
            bottom: 0px;
            margin: 0;

            &.open {
                border: $menu-margin solid $white;
            }

            a,
            .control {
                font-size: 1.2rem;
                line-height: 1.5rem;
            }

            .menu-links {
                padding-top: 30%;
                padding-left: 30%;
            }
        }
    }

    .menu.open {
        background-color: $primary;
        color: $white;
        pointer-events: all;

        .home-link {
            a {
                color: $white;
            }
        }

        .menu-links {
            padding-top: 30%;
            padding-left: 30%;

            .menu-link {
                a,
                .control {
                    color: $white;
                }
        
                a:hover,
                a:active,
                .control:not(:focus):hover {
                    color: $secondary;
                    cursor: pointer;
                }

                &.is-active {
                    a,
                    .control {
                        color: $secondary;
                    }
                }
            }
        }

        .sub-menu-separator {
            background-color: $white;
        }
    }
</style>