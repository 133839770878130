<template>
    <div class="content-item">
        <div class="item-head"
             ref="contentItem"
             v-bind:class="{ underline: isHovered, 'is-moving-item': isMovingItem }"
        >
            <div class="item-name"
                 @click="selectItem"
                 v-on="moveStartHandler"
                 v-bind:class="{ selected: item.id === selectedId }"
            >
                {{item[item.nameField] || item.title || item.id}}
            </div>
            <div class="item-button delete-button"
                 v-if="parent && (parent.childTemplate || parent.childTemplates) && item.id === selectedId"
                 @click="deleteThisItem"
            >
                -
            </div>
        </div>
        <div class="item-children"
             v-if="((item.id === selectedId) && item.children) || selectedIdPath.find((pathId) => pathId === item.id)">
            <ContentItem
                v-for="(child, childIndex) in item.children" :key="childIndex"
                :item="child"
                :parent="item"
                :idPath="itemIdPath"
                :selectedIdPath="selectedIdPath"
                :movingSibling="movingChild"
                @onSelectItem="selectItem"
                @onDeleteItem="deleteItem"
                @onCreateChild="createChild"
                @onChildMoveStart="onChildMoveStart"
                @onChildMoveEnd="onChildMoveEnd"
            />
            <div class="item-button create-button"
                 v-if="(item.childTemplate || item.childTemplates) && selectedIdPath.find((pathId) => pathId === item.id)"
                 @click="createChildHere">
                +
            </div>
        </div>
    </div>
</template>

<script>
    import ContentItem from '@/modules/blog-cms/content-item.vue'

    export default {
        name: 'ContentItem',
        components: {
            ContentItem
        },
        props: {
            idPath: Array,
            item: Object,
            parent: Object,
            selectedIdPath: Array,
            movingSibling: Object
        },
        data: function () {
            return {
                moveStartHandler: {
                    mousedown: this.onMoveStart,
                    touchstart: this.onMoveStart
                },
                isMovingItem: false,
                movingChild: null,
                isHovered: false
            }
        },
        computed: {
            selectedId(){
                return this.selectedIdPath && this.selectedIdPath[this.selectedIdPath.length - 1]
            },
            itemIdPath() {
                if (this.idPath && this.idPath.length) {
                    const idPath = this.idPath.slice()
                    idPath.push(this.item.id)
                    return idPath
                }
                else {
                    return [this.item.id]
                }
            }
        },
        watch: {
            movingSibling(siblingIsMoving, movedSibling) {
                if (siblingIsMoving) {
                    this.$refs.contentItem.addEventListener('mouseover', this.onHoverStart);
                    this.$refs.contentItem.addEventListener('mouseleave', this.onHoverEnd);
                }
                else {
                    this.$refs.contentItem.removeEventListener('mouseover', this.onHoverStart);
                    this.$refs.contentItem.removeEventListener('mouseleave', this.onHoverEnd);
                    if (this.isHovered) {
                        this.$store.dispatch('moveContentItem', {
                            parentItem: this.parent,
                            itemToMove: movedSibling,
                            itemBeforeNewIndex: this.item
                        })
                    }
                    this.onHoverEnd()
                }
            }
        },
        methods: {
            selectItem({ selectedItem, selectedIdPath }) {
                // pass up item-selection
                if (selectedIdPath) {
                    this.$emit('on-select-item', { selectedItem, selectedIdPath })
                }
                // or unselect/select this item
                else {
                    if (this.selectedIdPath[this.selectedIdPath.length - 1] === this.itemIdPath[this.itemIdPath.length - 1]) {
                        if (this.parent) {
                            const parentIdPath = [...this.itemIdPath]
                            parentIdPath.splice(-1, 1)
                            this.$emit('on-select-item', {
                                selectedItem: this.parent,
                                selectedIdPath: parentIdPath
                            })
                        }
                    }
                    else {
                        this.$emit('on-select-item', {
                            selectedItem: this.item,
                            selectedIdPath: this.itemIdPath
                        })
                    }
                }
            },
            createChildHere() {
                this.createChild(this.itemIdPath)
            },
            createChild(itemIdPath) {
                this.$emit('on-create-child', itemIdPath)
            },
            deleteThisItem() {
                this.deleteItem(this.itemIdPath)
            },
            deleteItem(itemIdPath) {
                this.$emit('on-delete-item', itemIdPath)
            },
            onMoveStart(event) {
                if (event.type === 'touchstart' || event.type === 'mousedown'){
                    if (this.parent && (this.parent.childTemplate || this.parent.childTemplates)) {
                        document.addEventListener('mouseup', this.onMoveEnd)
                        document.addEventListener('touchend', this.onMoveEnd)
                        this.$emit('on-child-move-start', this.item)
                        this.isMovingItem = true
                    }
                }
            },
            onMoveEnd() {
                document.removeEventListener('mouseup', this.onMoveEnd)
                document.removeEventListener('touchend', this.onMoveEnd)
                this.$emit('on-child-move-end')
                this.isMovingItem = false
            },
            onChildMoveStart(movingChild) {
                this.movingChild = movingChild
            },
            onChildMoveEnd() {
                this.movingChild = null
            },
            onHoverStart() {
                this.isHovered = true
            },
            onHoverEnd() {
                this.isHovered = false
            }
        }
    }
</script>

<style scoped lang="scss">
    .content-item {
        font-size: 1.5rem;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .item-head {
        display: flex;
    }

    .item-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .delete-button {
        margin-left: 15px;
    }

    .underline {
        text-decoration: underline;
    }

    .item-head:not(.underline) {
        .item-name:hover,
        .item-button:hover {
            color: $secondary;
        }
    }

    .item-head {
        .item-name.selected {
            color: $secondary;
        }
    }

    .item-head.is-moving-item {
        .item-button {
            display: none;
        }
    }

    .item-children {
        padding-left: $view-padding;
    }

    @media (max-width: 1000px) {
        .content-item {
            font-size: 1.2rem;
        }
    }
</style>
