<template>
    <div v-if="isProtecting" 
        class="start-lock"
        :class="{
            unlocked: isUnlocked
        }"
    >
        <div class="icons-box">
            <svg-icon v-for="iconPath in iconsPaths"
                :key="iconPath"
                type="mdi"
                :path="iconPath"
                @click="() => toggleIcon(iconPath)"
                size={1}
                :class="{
                    selected: selectedIcons.includes(iconPath)
                }"
            >
            </svg-icon>
        </div>
    </div>
</template>

<script>
import SvgIcon from '@jamescoyle/vue-icon';
import {
    mdiHorse,
    mdiKangaroo,
    mdiDolphin,
    mdiElephant,
    mdiFish,
    mdiGoogleDownasaur,
    mdiJellyfish,
    mdiKoala,
    mdiOwl,
    mdiPanda,
    mdiPenguin,
    mdiRabbit,
    mdiRodent,
    mdiSnail,
    mdiSpider,
    mdiTortoise,
    mdiArrowRightThick,
    mdiAutorenew,
    mdiAxisArrow,
    mdiTrayArrowUp,
    mdiTrayArrowDown,
    mdiGreenhouse,
    mdiImageFilterDrama,
    mdiMushroom,
    mdiFlowerTulipOutline,
    mdiMusicNoteEighth,
    mdiMusic,
    mdiHeadphones,
    mdiBell,
    mdiTargetAccount,
    mdiShieldCheck,
    mdiAccountReactivate,
    mdiAlpha,
    mdiBomb,
    mdiCardsHeart,
    mdiBowArrow,
    mdiBoomerang,
    mdiChessKing,
    mdiChessQueen,
    mdiGhost,
    mdiSkull,
    mdiScriptText,
    mdiSwordCross,
    mdiMotherHeart,
    mdiHuman,
    mdiAtom,
    mdiDna,
    mdiRocketLaunch,
    mdiScaleBalance,
    mdiPalette,
    mdiPen,
    mdiCakeVariant,
    mdiFoodApple,
    mdiFruitGrapes,
    mdiGlassCocktail,
    mdiUmbrellaOutline,
    mdiWhiteBalanceSunny,
    mdiWeatherNight,
    mdiSignal,
    mdiPineTree,
    mdiGasStation,
    mdiHome,
    mdiEmoticonCoolOutline,
    mdiEmoticonHappyOutline
} from '@mdi/js';

export default {
    name: 'StartLock',
    components: {
        SvgIcon
    },
    data() {
        return {
            combinations: [
                {
                    prompt: 'Wenn der Elefant den Apfel isst, stirbt er.',
                    icons: [mdiElephant, mdiFoodApple, mdiSkull]
                },
                {
                    prompt: 'Menschen werfen Bumerangs nach Kängaruhs.',
                    icons: [mdiKangaroo, mdiBoomerang, mdiHuman]
                },
                {
                    prompt: 'Der König und die Königin hören Musik.',
                    icons: [mdiChessKing, mdiChessQueen, mdiMusic]
                },
                {
                    prompt: 'Die Rakete tankt gute Laune.',
                    icons: [mdiRocketLaunch, mdiGasStation, mdiEmoticonHappyOutline]
                },
                {
                    prompt: 'Sonne und Mond sind im Gleichgewicht.',
                    icons: [mdiWhiteBalanceSunny, mdiWeatherNight, mdiScaleBalance]
                },
                {
                    prompt: 'Delfine trinken einen Pilzcocktail',
                    icons: [mdiDolphin, mdiMushroom, mdiGlassCocktail]
                },
                {
                    prompt: 'Der Dinosaurier wird Zuhause reaktiviert',
                    icons: [mdiGoogleDownasaur, mdiHome, mdiAccountReactivate],
                    client: 'Heidi Zgraggen'
                }
            ],
            iconsPaths: [
                mdiHorse,
                mdiKangaroo,
                mdiDolphin,
                mdiElephant,
                mdiFish,
                mdiGoogleDownasaur,
                mdiJellyfish,
                mdiKoala,
                mdiOwl,
                mdiPanda,
                mdiPenguin,
                mdiRabbit,
                mdiRodent,
                mdiSnail,
                mdiSpider,
                mdiTortoise,
                mdiArrowRightThick,
                mdiAutorenew,
                mdiAxisArrow,
                mdiTrayArrowUp,
                mdiTrayArrowDown,
                mdiGreenhouse,
                mdiImageFilterDrama,
                mdiMushroom,
                mdiFlowerTulipOutline,
                mdiMusicNoteEighth,
                mdiMusic,
                mdiHeadphones,
                mdiBell,
                mdiTargetAccount,
                mdiShieldCheck,
                mdiAccountReactivate,
                mdiAlpha,
                mdiBomb,
                mdiCardsHeart,
                mdiBowArrow,
                mdiBoomerang,
                mdiChessKing,
                mdiChessQueen,
                mdiGhost,
                mdiSkull,
                mdiScriptText,
                mdiSwordCross,
                mdiMotherHeart,
                mdiHuman,
                mdiAtom,
                mdiDna,
                mdiRocketLaunch,
                mdiScaleBalance,
                mdiPalette,
                mdiPen,
                mdiCakeVariant,
                mdiFoodApple,
                mdiFruitGrapes,
                mdiGlassCocktail,
                mdiUmbrellaOutline,
                mdiWhiteBalanceSunny,
                mdiWeatherNight,
                mdiSignal,
                mdiPineTree,
                mdiGasStation,
                mdiHome,
                mdiEmoticonCoolOutline,
                mdiEmoticonHappyOutline
            ],
            selectedIcons: []
        }
	},
    mounted() {
        this.shuffleIcons()
    },
    computed: {
        isProtecting() {
            return this.$store.getters.isProtecting()
        },
        isUnlocked() {
            return this.$store.getters.isUnlocked()
        }
    },
    methods: {
        shuffleIcons() {
            const array = this.iconsPaths
            let currentIndex = array.length,  randomIndex
            while (currentIndex != 0) {
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
            }
        },
        toggleIcon(iconPath) {
            const alreadySelectedIndex = this.selectedIcons.findIndex(item => item === iconPath)
            if (alreadySelectedIndex > -1) {
                this.selectedIcons.splice(alreadySelectedIndex, 1)
            }
            else {
                this.selectedIcons.push(iconPath)
            }
            this.combinations.forEach(combination => {
                const hasAllRequiredIcons = combination.icons.every(icon => this.selectedIcons.includes(icon))
                const hasRightCountOfIcons = combination.icons.length === this.selectedIcons.length
                if (hasAllRequiredIcons && hasRightCountOfIcons) {
                    this.isLocked = false
                    this.$store.commit('SET_IS_UNLOCKED', true)
                    setTimeout(() => {
                        this.$emit('unlock')
                    }, 1000)
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
    .start-lock {
        position: fixed;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $black;
        color: $white;
        z-index: $z-index-start-lock;
        opacity: 1;
        transition: opacity 1s;

        &.unlocked {
            pointer-events: none;
            opacity: 0;
        }

        .icons-box {
            max-width: $locker-max-width;
            margin-left: $small-padding;
            margin-right: $small-padding;
            display: flex;
            flex-flow: row wrap;

            svg {
                flex-grow: 1;
                flex-shrink: 0;
                flex-basis: 12.5%;
                width: $icon-size-l;
                cursor: pointer;

                &:hover,
                &.selected {
                    color: $secondary;
                }
            }
        }
    }
</style>
